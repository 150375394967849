<template>
	<div class="generate-qr">
		<div class="generate-qr-top">
			<h2 class="generate-qr-header">{{ title }}</h2>
			<p>{{ description }}</p>
			<h4>ID {{ sessionId }}</h4>
		</div>
		<QrCode :text="qrCodeText" />
		<div class="generate-qr-num-scan">
			<span class="generate-qr-num-scan-text">{{
				$t('sessionParking.generateQrCode.scanTitle')
			}}</span>
			<span class=""
				><strong>{{ numOfScan }}</strong> {{ $t('sessionParking.generateQrCode.scanNumber') }}
				{{ totalScan }}</span
			>
		</div>

		<div class="generate-qr-cancel-session-btn" v-if="checkCancelSession()" @click="openModal">
			{{ $t('sessionParking.cancelSessionCta') }}
		</div>

		<div class="generate-qr-info" v-if="type === 'session-start-qr' || type === 'qr-2'">
			<img src="../../assets/images/session/icon_warning_white.svg" />
			<p class="generate-qr-info-text" v-html="message"></p>
		</div>
		<div
			class="generate-qr-action"
			v-if="
				type === 'main-door-qr' || type === 'main-door-ext-qr' || type === 'main-door-pickup-qr'
			"
		>
			<div
				class="generate-qr-action-btn"
				@click="endSession"
				v-if="prevNumOfScan > 0 && this.sessionQrData.length > 1 && type === 'entering-qr'"
			>
				Je suis déjà rentré
			</div>
			<p v-html="message"></p>
			<div class="generate-qr-action-timer">{{ formattedTimer }}</div>
		</div>
		<div
			class="generate-qr-action"
			v-if="type === 'main-door-qr' || type === 'main-door-pickup-qr'"
		>
			<div class="generate-qr-action-btn" @click="skipMainDoor">
				{{ $t('sessionParking.generateQrCode.skipQr') }}
			</div>
			<p v-html="message" v-if="type !== 'main-door-pickup-qr'"></p>
		</div>
		<p class="generate-qr-text" v-if="type === 'session-end-qr'">
			{{ message }}
		</p>
		<div class="generate-qr-footer">
			<span class="" v-html="$t('sessionParking.generateQrCode.disclaimer')"></span>
		</div>

		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h1>{{ $t('sessionParking.modal.cancelSessionModal.modalTitle') }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ $t('sessionParking.modal.cancelSessionModal.modalDescription') }}
				</p>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">
					{{ $t('sessionParking.cancelSessionCta') }}
				</button>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import QrCode from '@/components/QrCode.vue'
import { mapState, mapActions } from 'vuex'
import SessionModal from '@/components/SessionModal.vue'
import { BOURSE } from '../../store/modules/session'
import io from 'socket.io-client'
import { config } from '@/config'

export default {
	name: 'GenerateQr',
	components: {
		QrCode,
		SessionModal,
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		sessionId: {
			type: String,
			required: true,
		},
		numOfScan: {
			type: Number,
			required: true,
		},
		qrCodeText: {
			type: String,
			required: true,
		},
		totalScan: {
			type: Number,
			required: true,
		},
		prevNumOfScan: {
			type: Number,
		},
		sessionQrData: {
			type: Array,
		},
		parkingId: {
			type: Number,
		},
		message: {
			type: String,
			required: true,
		},
		timeLimit: {
			type: Number,
			required: false,
		},
		startTime: {
			type: Number,
			required: false,
		},
		endSession: {
			type: Function,
		},
		skipMainDoor: {
			type: Function,
		},
	},
	data() {
		return {
			timer: null,
			initialTimeLimit: this.timeLimit * 60, // minutes in seconds
			timerDuration: 0,
			startingTime: this.startTime,
			lastUpdateTime: Date.now(),
			showModal: false,
		}
	},
	computed: {
		formattedTimer() {
			const minutes = Math.floor(this.timerDuration / 60)
			const seconds = this.timerDuration % 60
			return `${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`
		},
	},
	methods: {
		...mapActions('session', ['getSessionById']),
		...mapActions('session', ['cancelCurrentSession']),
		openModal() {
			// Show the default modal
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async confirmAction() {
			this.cancelSession()
			this.closeModal()
		},
		async loadSessionPeriodically(numericSessionId) {
			if (numericSessionId) {
				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						sessionId: numericSessionId,
					}
					try {
						// Dispatch the action properly
						await this.getSessionById(sessionProps)
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
		async cancelSession() {
			try {
				await this.cancelCurrentSession(parseInt(this.sessionId))
				this.loadSessionPeriodically(parseInt(this.sessionId))
			} catch (error) {
				console.error('Error fetching session:', error)
			}
		},
		calculateTimerDuration() {
			if (this.startingTime) {
				const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
				const elapsedTime = currentTime - Math.floor(this.startingTime / 1000) // Time elapsed since startTime in seconds
				const remainingTime = Math.max(0, this.initialTimeLimit - elapsedTime) // Remaining time in seconds
				return remainingTime // Ensure timer never goes negative
			} else {
				return this.initialTimeLimit // If no startTime, return initial time limit
			}
		},
		updateTimer() {
			const currentTime = Date.now()
			const timeDiff = currentTime - this.lastUpdateTime // Time difference in ms
			if (timeDiff >= 1000) {
				this.timerDuration = this.calculateTimerDuration()
				this.lastUpdateTime = currentTime

				if (this.timerDuration <= 0) {
					clearInterval(this.timer)
					const session = localStorage.getItem('qrTimeout')
					if (session === 'true') {
						this.$router.push('/how-session-start')
					} else {
						localStorage.setItem('qrTimeout', 'true')
						localStorage.removeItem('mainDoorPickupStartTime')
						this.$router.push('/how-session-start')
					}
				}
			}

			this.timer = requestAnimationFrame(this.updateTimer) // Call this method next frame
		},
		checkCancelSession() {
			const currentTime = new Date()
			const hours = currentTime.getHours()
			if (
				(this.parkingId == BOURSE.ID &&
					hours >= BOURSE.NIGHT.START &&
					hours <= BOURSE.NIGHT.END &&
					this.type === 'main-door-qr' &&
					this.numOfScan === 0) ||
				(this.parkingId !== BOURSE.ID && this.type === 'main-door-qr' && this.numOfScan === 0)
			) {
				return true
			} else if (
				(this.parkingId == BOURSE.ID &&
					(hours < BOURSE.NIGHT.START || hours > BOURSE.NIGHT.START) &&
					hours > BOURSE.NIGHT.END &&
					this.type === 'session-start-qr' &&
					this.numOfScan === 0) ||
				(this.parkingId !== BOURSE.ID && this.type === 'session-start-qr' && this.numOfScan === 0)
			) {
				return true
			} else {
				return false
			}
		},
	},
	mounted() {
		const mainDoorPickupQrTime = localStorage.getItem('mainDoorPickupStartTime')
		if (mainDoorPickupQrTime) {
			this.startingTime = mainDoorPickupQrTime
		}
		if (this.type === 'entering-qr' || this.type === 'main-door-pickup-qr') {
			this.timerDuration = this.calculateTimerDuration()
			this.timer = requestAnimationFrame(this.updateTimer) // Start the animation frame loop
		}
	},
	beforeDestroy() {
		cancelAnimationFrame(this.timer)
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/generateQr';
</style>
