<template>
	<div class="bg">
		<Loading v-if="isLoading || loading || profileLoading" />
		<PreviousButton @previous-step="previousStep" />
		<div v-if="progress === 0" class="mollie">
			<MollieStep2 @received-token="handleReceivedToken" :dataFromParent="parentData" />
		</div>
		<modifier
			v-if="progress === 1"
			@new-method="newPaymentMethod"
			:mandates="mandates"
			@radio-selected="handleRadioSelected"
		/>
		<step2
			v-if="progress === 2"
			:parking="parking"
			:token="token"
			:mandate="mandate"
			:session="session"
			@previous-step="previousStep"
		/>
		<sessionNewPaymentMethod v-if="progress === 3" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading.vue'
import PreviousButton from '@/components/PreviousButton.vue'
import MollieStep2 from '../../components/subscription/MollieStep2.vue'
import step2 from '../../components/session/step2.vue'
import modifier from '../../components/session/modifier.vue'
import sessionNewPaymentMethod from '../../components/session/sessionNewPaymentMethod.vue'
import ValidationRequired from '@/mixins/validation-required'
import SessionRequired from '@/mixins/session-required'
import PhoneVerificationRequired from '@/mixins/phone-verification-required'
export default {
	name: 'HowItPay',
	components: {
		PreviousButton,
		MollieStep2,
		step2,
		modifier,
		sessionNewPaymentMethod,
		Loading,
	},
	mixins: [ValidationRequired, PhoneVerificationRequired],
	data() {
		return {
			progress: null,
			parentData: 'session',
			token: null,
			mandate: null,
			sessionEnd: false,
			parking: null,
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.session.isLoading,
			loading: (state) => state.parkings.loading,
			parkingById: (state) => state.parkings.parkingById,
			mandates: (state) => state.session.mandates,
			storeToken: (state) => state.session.token,
			session: (state) => state.session,
			profileLoading: (state) => state.profile.loading,
		}),
	},
	watch: {
		$route(to, from) {
			if (to.query.progress) {
				this.progress = parseInt(to.query.progress)
			} else {
				this.progress = 2
			}
		},
	},
	async beforeMount() {
		const session = localStorage.getItem('sessionEnd')
		if (this.$route.query.parkingId == undefined) {
			this.$router.push('/')
		} else if (
			this.$route.query.parkingId != undefined &&
			this.$route.query.redirect == undefined
		) {
			console.log('beforeMandes', this.$route)
			await this.getAllMandates()
			console.log('afterMandates', this.$route)
			await this.getParkingById(parseInt(this.$route.query.parkingId))

			// this is realted to sessionend if the payment is failed
			if (this.$route.query.session != undefined) {
				const session = localStorage.getItem('sessionEnd')
				if (session === 'true') {
				} else {
					localStorage.setItem('sessionEnd', 'true')
				}
				const newQueryParams = { ...this.$route.query }
				if (this.$route.query.payment == 'failed') {
					this.progress = newQueryParams.progress = 1
				}
				delete newQueryParams.session
				const newPath = `${window.location.pathname}?${new URLSearchParams(
					newQueryParams
				).toString()}`
				window.history.replaceState({}, '', newPath)
				return
			}

			if (this.$route.query.progress) {
				this.progress = parseInt(this.$route.query.progress)
			} else {
				if (this.mandates.length > 0) {
					this.progress = 2
				} else {
					this.progress = 0
				}
			}

			// Check if mandates array is empty
			if (this.mandates.length > 0) {
				const defaultMandate = this.mandates.find((m) => m.default)
				if (this.storeToken) {
					this.token = this.storeToken
					this.mandate = this.mandates.find((m) => m.id == this.storeToken)
				} else {
					this.token = defaultMandate?.id || this.mandates[0].id
					this.mandate = defaultMandate || this.mandates[0]
				}
				this.parking = this.parkingById
			}
		} else if (
			this.$route.query.parkingId != undefined &&
			this.$route.query.redirect != undefined
		) {
			this.setLoading(true)
			await this.getParkingById(parseInt(this.$route.query.parkingId))
			const newQueryParams = { ...this.$route.query }
			delete newQueryParams.redirect
			const newPath = `${window.location.pathname}?${new URLSearchParams(
				newQueryParams
			).toString()}`
			window.history.replaceState({}, '', newPath)
			const newResponse = await this.getAllMandates()
			console.log('newResponse', newResponse)
			await this.getParkingById(parseInt(this.$route.query.parkingId))

			// Check if mandates array is empty
			if (this.mandates.length > 0) {
				if (this.storeToken) {
					this.token = this.storeToken
					this.mandate = this.mandates.find((m) => m.id == this.storeToken)
				} else {
					this.token = this.mandates[0].id
					this.mandate = this.mandates[0]
				}
				this.parking = this.parkingById
				this.progress = 2
			} else {
				this.progress = 0
			}

			// this is realted to sessionend if the payment is failed
			if (this.$route.query.session != undefined || session === 'true') {
				const newQueryParams = { ...this.$route.query }
				delete newQueryParams.session
				delete newQueryParams.redirect
				const newPath = `${window.location.pathname}?${new URLSearchParams(
					newQueryParams
				).toString()}`
				window.history.replaceState({}, '', newPath)
			}
			if (this.$route.query.paymentId) {
				this.progress = 0
				return this.$router.replace({
					path: this.$route.path,
					query: { ...newQueryParams, progress: 0 },
				})
			}
			this.progress = 1
			return this.$router.replace({
				path: this.$route.path,
				query: { ...newQueryParams, progress: 1 },
			})
		}
		/* 
		if (this.session.sessionCreate && this.session.sessionCreate.length > 0) {
			this.success(this.$t('howItPay.existingSession'))
			this.$router.push({ name: 'MainDoorQr' })
		} else if (this.session.sessionStart && this.session.sessionStart.length > 0) {
			this.success(this.$t('howItPay.existingSession'))
			this.$router.push({ name: 'SessionStartQr' })
		} else if (this.session.sessionActive && this.session.sessionActive.length > 0) {
			this.success(this.$t('howItPay.existingSession'))
			this.$router.push({ name: 'HowSessionStart' })
		} else if (this.session.sessionStop && this.session.sessionStop.length > 0) {
			this.success(this.$t('howItPay.existingSession'))
			this.$router.push({ name: 'EnteringQr' })
		} else if (this.session.sessionEnd && this.session.sessionEnd.length > 0) {
			this.success(this.$t('howItPay.existingSession'))
			this.$router.push({ name: 'SessionEndQr' })
		} */
	},
	mounted() {
		const session = localStorage.getItem('sessionEnd')
		if (session === 'true') {
			this.sessionEnd = true
		}
	},
	methods: {
		...mapMutations('session', {
			setLoading: 'setLoading',
		}),
		...mapMutations('profile', {
			setProfileLoading: 'setLoading',
		}),
		...mapActions({
			getParkingById: 'parkings/getParkingById',
			getAllMandates: 'session/getAllMandates',
			updateSession: 'session/updateSession',
			success: 'alert/success',
			defaultPaymentMethod: 'profile/defaultPaymentMethod',
		}),
		nextStep() {
			this.progress += 1
		},
		previousStep(num) {
			if (num > 0) {
				this.progress = num
				this.$router.push({
					path: this.$route.path,
					query: { ...this.$route.query, progress: this.progress },
				})
			} else {
				this.$router.go(-1)
			}
		},
		async handleReceivedToken(token) {
			await this.getAllMandates()
			if (this.sessionEnd) {
				this.progress = 1
			} else {
				if (this.progress == 0) {
					this.token = token
					this.mandate = this.mandates.find((m) => m.id == token)
					this.parking = this.parkingById
					this.progress = 2
				}
			}
		},
		async handleRadioSelected(id) {
			this.token = id
			this.mandate = this.mandates.find((m) => m.id == id)
			const data = await this.defaultPaymentMethod({
				customerId: this.mandate?.customerId,
				mandateId: this.mandate?.id,
			})
			this.setProfileLoading(false)
			if (this.sessionEnd) {
				const props = {
					paymentMethod: this.token,
				}
				const res = await this.updateSession(props)
				if (res) {
					this.$router.push('/how-session-start')
				}
			} else {
				this.progress += 1
				this.$router.push({
					path: this.$route.path,
					query: { ...this.$route.query, progress: this.progress },
				})
			}
		},
		newPaymentMethod() {
			this.progress = 0
			this.$router.push({
				path: this.$route.path,
				query: { ...this.$route.query, progress: this.progress },
			})
		},
		navigateToPreviousPage() {
			const parkingId = this.$route.query.parkingId
			if (parkingId) {
				this.$router.push(`/parkings?parkingId=${parkingId}`)
			}
		},
	},
	beforeDestroy() {
		// Delete sessionEnd
		localStorage.removeItem('sessionEnd')
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
.mollie {
	width: 30%;
	/* position: relative; */
	margin: 0px auto;
}

@media screen and (max-width: 768px) {
	.mollie {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.roww {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}
}
</style>
