/* eslint-disable vue/no-unused-vars */
<template>
	<div>
		<div
			:style="{ 'margin-bottom': '20px', borderBottom: '1px solid grey' }"
			class="admin-item-wrapper"
		>
			<div class="admin-item-info">
				<div class="admin-item-info-row header-row">
					<div class="header-block">
						<p class="admin-item-info-text">
							<strong>{{ subscriptionStatusName(subscription).toUpperCase() }}</strong>
						</p>
					</div>
					<div class="header-block">
						<p class="admin-item-info-text">
							<strong>{{ subscriptionName(subscription) }}</strong>
							<br />
							{{ subscriptionBikeType(subscription) }}
						</p>
					</div>
					<div class="header-block">
						<p class="admin-item-info-text">
							<strong>{{ $t('subscription.overviewHeaders.createdDate') }}:</strong>
							<br />
							{{ date(subscription.createdAt) }}
						</p>
					</div>
					<div class="header-block">
						<p class="admin-item-info-text">
							<strong>{{ $t('subscription.overviewHeaders.startDate') }}:</strong>
							<br />
							{{ date(subscription.start) }}
						</p>
					</div>
					<div class="header-block">
						<div class="admin-item-info-field">
							<p class="admin-item-info-text">
								<strong>{{ $t('subscription.overviewHeaders.expiryDate') }}: </strong>
								<br />
								<span v-if="subscription.status < 3">{{
									date(subscription.end)
								}}</span>
								<date-picker
									v-else
									:value="subscription.end"
									:clearable="false"
									lang="en"
									format="DD-MM-YYYY"
									confirm
									confirmText="Update"
									inputClass="input-field small"
									@confirm="doUpdateExpiry($event, subscription.id)"
								/>
							</p>
						</div>
					</div>
					<div class="header-block">
						<p class="admin-item-info-text">
							<strong
								>{{
									isActive() || isPending()
										? $t('subscription.overviewHeaders.remainingMonths')
										: $t('subscription.overviewHeaders.unusedMonths')
								}}:</strong
							>
							<br />
							{{ subscription.remainingMonths }}
						</p>
					</div>
					<div v-if="subscription.fromChild" class="admin-item-info-field">
						<div class="header-block">
							<p class="admin-item-info-text">
								<strong>{{ $t('profile.familyAccountLabel') }}</strong>
								<br />
								{{ subscription.childName }}
							</p>
						</div>
					</div>
				</div>
				<div :style="{ alignItems: 'inherit' }" class="admin-item-info-row">
					<div v-if="subAction.open" class="admin-item-col">
						<div>
							<div class="admin-regularization-form-box">
								<span class="field-title"><strong>Regularization Type:</strong></span>
								<div>
									<input
										id="penalty"
										:checked="subAction.regularization === 'penalty'"
										value="penalty"
										type="radio"
										name="regularization"
										class="modal-request-select-option-input"
										@change="selectRegularization"
									/>
									<label for="penalty">Penalty</label>
									<input
										v-if="!isLegacy()"
										id="compensation"
										:checked="!isLegacy() && subAction.regularization === 'compensation'"
										value="compensation"
										type="radio"
										name="regularization"
										class="modal-request-select-option-input"
										@change="selectRegularization"
									/>
									<label v-if="!isLegacy()" for="compensation">Compensation (stripe)</label>
									<input
										id="manual-compensation"
										:checked="subAction.regularization === 'manual-compensation'"
										:disabled="!hasBankAcountFilledIn"
										value="manual-compensation"
										type="radio"
										name="regularization"
										class="modal-request-select-option-input"
										@change="selectRegularization"
									/>
									<label for="compensation">Manual Compensation</label>
								</div>
							</div>
							<div class="admin-regularization-form-box">
								<span class="field-title"><strong>Months:</strong></span>
								<input
									v-model="subAction.months"
									type="number"
									class="input-field small"
									placeholder="Number of months"
									name="regularizeMonths"
								/>
							</div>
							<div class="admin-regularization-form-box">
								<span class="field-title"><strong>Description:</strong></span>
								<input
									v-model="subAction.description"
									type="text"
									class="input-field small"
									placeholder="reason"
									name="regularizeMonths"
								/>
							</div>
							<div class="admin-item-info-field">
								<button
									:disabled="!subAction.regularization"
									:class="{ disabled: !subAction.regularization }"
									class="button small blue"
									@click="confirmSubAction()"
								>
									{{ $t('admin.subscriptionActions.confirmButton') }}
								</button>
								<p class="admin-item-info-text splitter">&nbsp;</p>
								<button class="button small red" @click="closeSubAction()">Cancel</button>
							</div>
						</div>
					</div>
					<div v-else class="admin-item-col">
						<div class="admin-item-info-field" @click="openEditSpotNumber">
							<p class="admin-item-info-text">
								<span class="fake-link">
									<strong>{{ $t('subscription.overviewHeaders.spotNumber') }}:</strong>
								</span>
								<span v-show="!spotNumberEdit">
									{{ subscription.spotNumber || '&lt;edit&gt;' }}</span
								>
							</p>
							<form
								v-show="spotNumberEdit"
								class="admin-item-info-form"
								@submit.prevent="doUpdateSpotNumber($event, subscription.id)"
							>
								<input
									:value="subscription.spotNumber"
									type="number"
									class="input-field small"
									placeholder="spot"
									name="spotNumber"
								/>
								<button class="button blue small subscription-key-confirm">OK</button>
								<button
									class="button red small subscription-key-confirm"
									@click="closeEditSpotNumber"
								>
									X
								</button>
							</form>
						</div>
						<div class="admin-item-info-field" @click="openEditKeyNumber">
							<p class="admin-item-info-text">
								<span class="fake-link"><strong>Key Number:</strong></span>
								<span v-show="!keyNumberEdit"> {{ subscription.keyNumber || '&lt;edit&gt;' }}</span>
							</p>
							<form
								v-show="keyNumberEdit"
								class="admin-item-info-form"
								@submit.prevent="doUpdateKeyForSub($event, subscription.id)"
							>
								<input
									:value="subscription.keyNumber"
									class="input-field small"
									placeholder="key number"
									name="keyNumber"
								/>
								<button class="button blue small subscription-key-confirm">OK</button>
								<button
									class="button red small subscription-key-confirm"
									@click="closeEditKeyNumber"
								>
									X
								</button>
							</form>
						</div>
						<div class="admin-item-info-field">
							<p class="admin-item-info-text">
								<span><strong>Payment Method:</strong></span>
								<span> {{ subscription.paymentMethod || '-' }}</span>
							</p>
						</div>
						<div class="admin-item-info-field">
							<button
								v-if="isPending()"
								class="button blue small subscription-key-confirm"
								@click="retrySubscription({ subscriptionId: subscription.id })"
							>
								retry
							</button>
							<button
								v-if="isActiveOrPendingNotPaid()"
								class="button red small subscription-key-confirm"
								@click="doCancelNowSubscription()"
							>
								Cancel Now
							</button>

							<p
								v-if="isActive() && subscription.cancelRequestedAt"
								class="admin-item-info-text splitter"
							>
								&nbsp;
							</p>
							<p
								v-if="isActive() && subscription.cancelRequestedAt"
								class="admin-item-info-text cancel-requested-text"
							>
								Cancel requested: <br />
								{{ date(subscription.cancelRequestedAt) }}
							</p>
							<p v-if="isActive() || isPending()" class="admin-item-info-text splitter">&nbsp;</p>
							<div
								v-if="isActive() && subscription.cancelRequestedAt"
								class="button red small subscription-key-confirm"
								@click="doUndoCancelRequestSubscription()"
							>
								Undo
							</div>
						</div>
						<div class="admin-item-info-field" style="margin-top: 10px">
							<button
								v-if="isActiveOrPendingNotPaid()"
								class="button blue small subscription-key-confirm"
								@click="resendPaymentLink({ sub: subscription })"
							>
								Send Payment Link
							</button>
						</div>
						<div class="admin-item-info-field toggle-invoice-link">
							<div
								v-if="isActive() || isCancelled()"
								class="button small blue"
								@click="initializeSubAction($event, 'regularization')"
							>
								Regularization
							</div>
								<!-- v-if="subscription.mutualRefunds.length > 0 || subscription.invoiceItems.length > 0" -->
							<a
								v-if="subscription.refunds.length > 0 || subscription.invoiceItems.length > 0"
								href="#"
								class="simple-link invoice-info"
								@click="toggleInvoices"
								>toggle invoices and refunds</a
							>
							<span v-else class="invoice-info">No Invoices</span>
						</div>
					</div>
					<fieldset
						v-if="subscription.subscriptionType.name !== 'BigParking'"
						class="admin-item-col deposit-box"
					>
						<legend class="deposit-form-label">
							{{ $t('subscription.deposit') }}
						</legend>
						<template v-if="subscription.deposits.length > 0">
							<div
								v-for="(depo, idx) in subscription.deposits"
								:key="depo.id"
								class="deposit-form-container"
								:class="`item-${idx}`"
							>
								<DepositForm
									:isSubActive="isActive()"
									:deposit="depo"
									:hasInvoicedANewKey="hasInvoicedANewKey"
									:invoiceItems="subscription.invoiceItems"
									class="admin-item-info-row"
									@refund-deposit="doRefundDeposit"
									@manual-refund-deposit="doManualRefundDeposit"
									@close-deposit="doCloseDeposit"
									@add-key="doAddNewKeyforSubscription"
								/>
							</div>
						</template>
						<div :style="{ justifyContent: 'center' }" class="admin-item-info-field action-buttons">
							<div v-if="subscription.externalDeposit">
								<div :style="{ textAlign: 'center' }" class="notification warning">
									EXTERNAL DEPOSIT
								</div>
								<form
									v-if="isActive()"
									:style="{ marginTop: '5px' }"
									class="admin-item-info-form"
									@submit.prevent="doAddNewKeyforSubscription()"
								>
									<button
										:style="{ marginLeft: '0' }"
										class="button blue small subscription-deposit-action"
									>
										Invoice new key
									</button>
								</form>
							</div>
							<template v-else>
								<div v-if="isActive()" class="button small blue" @click="doCreateDeposit()">
									add deposit
								</div>
							</template>
						</div>
					</fieldset>
				</div>

				<div v-if="showInvoiceItems">
					<div class="admin-item-row invoice-items-row header-row">
						<div class="admin-item-col header">Invoice Type</div>
						<div class="admin-item-col smaller header">Amount</div>
						<div class="admin-item-col header small">Status</div>
						<div class="admin-item-col header small">Paid at</div>
						<div class="admin-item-col header">Created at</div>
						<div class="admin-item-col header">Refunds</div>
						<div class="admin-item-col header"></div>
					</div>
					<UserSubInvoiceItem
						v-for="(item, index) in subscription.invoiceItems"
						:key="`ivi-${index}`"
						:item="item"
						class="admin-item-row invoice-items-row"
						@refundNewKey="doRefundNewKey"
						@manualRefundNewKey="doManualRefundNewKey"
						@resendPaymentLinkInvoiceItem="resendPaymentLinkInvoiceItem"
					/>

					<div class="admin-item-row invoice-items-row header-row header-refunds">
						<div class="admin-item-col header small">Refund Type</div>
						<div class="admin-item-col smaller header">Amount</div>
						<div class="admin-item-col header small">Status</div>
						<div class="admin-item-col header">Description</div>
						<div class="admin-item-col header small">Created at</div>
					</div>
					<UserSubRefund
						v-for="(item, index) in allSubRefunds()"
						:key="`ref-${index}`"
						:item="item"
						class="admin-item-row invoice-items-row"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { date } from '@/helpers'
import {
	subscriptionName,
	subscriptionStatusName,
	subscriptionBikeType,
} from '@/store/modules/subscription'
import DatePicker from 'vue2-datepicker'
import { DEFAULT_PRICES, DEPOSIT_STATES, SUBSCRIPTION_STATES } from '@/config'
import { logger } from '@/logger'
import { concat, map, flatten } from 'lodash/fp'
import DepositForm from '@/components/admin/DepositForm'
import UserSubInvoiceItem from '@/components/admin/UserSubInvoiceItem'
import UserSubRefund from '@/components/admin/UserSubRefund'

export default {
	name: 'UserSubscription',
	components: {
		DatePicker,

		// Modal,
		DepositForm,
		UserSubInvoiceItem,
		UserSubRefund,
	},
	props: {
		subscription: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			local: {},
			subAction: {
				open: false,
				type: '',
				months: 1,
				description: '',
				initialDeposit: { price: DEFAULT_PRICES.deposit, status: DEPOSIT_STATES.active },
				regularization: '',
			},
			hasInvoicedANewKey: false,
			showInvoiceItems: false,
			spotNumberEdit: false,
			keyNumberEdit: false,
		}
	},
	computed: {
		...mapState({
			user: (state) => state.admin.userForm.user,
		}),
		hasBankAcountFilledIn() {
			return this.user.bankAccount
		},
	},
	mounted() {},

	methods: {
		...mapActions('admin', {
			retrySubscription: 'retrySubscription',
			updateKeyForSub: 'updateKeyForSub',
			updateSpotNumber: 'updateSpotNumber',
			updateExpiryDateForSub: 'updateExpiryDateForSub',
			addChargeForSubscription: 'addChargeForSubscription',
			addKeyForSubscription: 'addKeyForSubscription',
			refundForSubscription: 'refundForSubscription',
			refundDeposit: 'refundDeposit',
			refundNewKey: 'refundNewKey',
			closeDeposit: 'closeDeposit',
			createDeposit: 'createDeposit',
			cancelNowSubscription: 'cancelNowSubscription',
			cancelSubscription: 'cancelSubscription',
			undoCancelRequestSubscription: 'undoCancelRequestSubscription',
			resendPaymentLink: 'resendPaymentLink',
			resendPaymentLinkInvoiceItem: 'resendPaymentLinkInvoiceItem',
		}),
		...mapMutations('admin', {
			showUserForm: 'showUserForm',
		}),
		async doUpdateKeyForSub(e, subId) {
			const keyUpdate = await this.updateKeyForSub({
				keyNumber: e.target.keyNumber.value,
				subId,
				userId: this.user.id,
			})
			this.keyNumberEdit = false
			if (keyUpdate) {
				this.subscription.keyNumber = e.target.keyNumber.value || null
			}
		},
		async doUpdateSpotNumber(e) {
			const spotUpdate = await this.updateSpotNumber({
				spotNumber: parseInt(e.target.spotNumber.value, 10),
				subId: this.subscription.id,
				userId: this.user.id,
			})
			this.spotNumberEdit = false
			if (spotUpdate){
				this.subscription.spotNumber = parseInt(e.target.spotNumber.value, 10)
			}
		},
		doUpdateExpiry(e, subId) {
			this.updateExpiryDateForSub({ expiryDate: e.getTime(), subId, userId: this.user.id })

			// if (confirm(`Are you sure you want to update the Expiry Date to ${this.date(e.getTime())}`)) {
			// }
		},

		doCloseDeposit(depositId) {
			if (confirm('Are you sure you want to close this deposit?')) {
				return this.closeDeposit({ sub: this.subscription, depositId })
			}

			return false
		},

		doRefundDeposit(depositId) {
			if (confirm('Are you sure you want to refund this deposit?')) {
				return this.refundDeposit({ sub: this.subscription, depositId })
			}

			return false
		},

		doRefundNewKey(itemId) {
			if (confirm('Are you sure you want to refund this new Key?')) {
				return this.refundNewKey({ subId: this.subscription.id, itemId })
			}

			return false
		},

		doManualRefundNewKey(itemId) {
			if (confirm('Are you sure you want to refund this new Key?')) {
				return this.refundNewKey({ subId: this.subscription.id, itemId, manual: true })
			}

			return false
		},

		doManualRefundDeposit(depositId) {
			if (confirm('Are you sure you want to refund this deposit?')) {
				return this.refundDeposit({ sub: this.subscription, depositId, manual: true })
			}

			return false
		},

		doAddNewKeyforSubscription() {
			if (confirm('Are you sure you want to infoice a new key?')) {
				this.hasInvoicedANewKey = true

				return this.addKeyForSubscription({
					sub: this.subscription,
					amount: DEFAULT_PRICES.deposit,
				})
			}

			return false
		},

		isPending() {
			return this.subscription.status === SUBSCRIPTION_STATES.pending
		},

		isCancelled() {
			return this.subscription.status === SUBSCRIPTION_STATES.cancelled
		},

		isLegacy() {
			if (this.subscription.stripeId) {
				return !this.subscription.stripeId
			} else if (this.subscription.mollieId) {
				return !this.subscription.mollieId
			} else {
				return true
			}
		},

		isActive() {
			return [SUBSCRIPTION_STATES.active, SUBSCRIPTION_STATES.cancelAtEnd].includes(
				this.subscription.status
			)
		},
		isActiveOrPendingNotPaid() {
			return (
				this.isActive() ||
				parseInt(this.subscription.paymentStatus, 10) === SUBSCRIPTION_STATES.pending
			)
		},

		initializeSubAction(e, type) {
			logger.silly('initializing sub modal', type)
			this.subAction.open = true
			this.subAction.type = type
		},

		closeSubAction() {
			this.subAction.open = false
			this.subAction.type = ''
		},

		confirmSubAction() {
			switch (this.subAction.type) {
				case 'regularization':
					if (this.subAction.regularization === 'penalty') {
						this.addChargeForSubscription({
							sub: this.subscription,
							months: parseInt(this.subAction.months, 10),
							description: this.subAction.description,
						})
					} else if (this.subAction.regularization === 'manual-compensation') {
						this.refundForSubscription({
							sub: this.subscription,
							months: parseInt(this.subAction.months, 10),
							manual: true,
							description: this.subAction.description,
						})
					} else if (!this.isLegacy()) {
						this.refundForSubscription({
							sub: this.subscription,
							months: parseInt(this.subAction.months, 10),
							manual: false,
							description: this.subAction.description,
						})
					} else {
						alert('invalid action')
					}

					break

				// case 'addKey':
				// 	this.addKeyForSubscription({ sub: this.subscription, amount: DEFAULT_PRICES.deposit })
				// 	break
				case 'addDeposit':
					Object.assign(this.subscription, { deposit: this.subAction.initialDeposit })
					this.createDeposit(this.subscription)
					break
				default:
					alert('invalid action')
			}

			this.closeSubAction()
		},

		doCreateDeposit() {
			if (confirm('Are you sure you want to add a deposit on this subscription?')) {
				Object.assign(this.subscription, { deposit: this.subAction.initialDeposit })

				return this.createDeposit(this.subscription)
			} else {
				return false
			}
		},

		doCancelNowSubscription() {
			if (confirm('Are you sure you want to cancel this subscription IMMEDIATELY?')) {
				return this.cancelNowSubscription({ subscriptionId: this.subscription.id })
			}

			return false
		},

		doUndoCancelRequestSubscription() {
			if (confirm('Are you sure you want to undo this cancel request?')) {
				return this.undoCancelRequestSubscription({ subscriptionId: this.subscription.id })
			}

			return false
		},

		// doCancelAtEndSubscription() {
		// 	if (confirm('Are you sure you want to cancel this subscription at the end of the period?')) {
		// 		return this.cancelSubscription({ subscriptionId: this.subscription.id })
		// 	}

		// 	return false
		// },

		selectRegularization(e) {
			this.subAction.regularization = e.target.id
		},

		toggleInvoices(e) {
			e.preventDefault()

			this.showInvoiceItems = !this.showInvoiceItems
		},

		openEditSpotNumber() {
			if (!this.spotNumberEdit) {
				this.spotNumberEdit = true
			}
		},
		closeEditSpotNumber(e) {
			e.preventDefault()
			this.spotNumberEdit = false
			e.stopPropagation()
		},

		openEditKeyNumber() {
			if (!this.keyNumberEdit) {
				this.keyNumberEdit = true
			}
		},
		closeEditKeyNumber(e) {
			e.preventDefault()
			this.keyNumberEdit = false
			e.stopPropagation()
		},

		allSubRefunds() {
			return concat(
				// this.subscription.manualRefunds,
				this.subscription.refunds,
				flatten(map((i) => i.refunds, this.subscription.invoiceItems))
			)
		},

		date,
		subscriptionName,
		subscriptionStatusName,
		subscriptionBikeType,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin';

.notification.warning {
	padding: 5px 5px 2px 5px;
	border: solid $orange 1px;
	background-color: lighten($orange, 30%);
	color: $red;
	font-weight: bold;
}
.admin-regularization-form-box {
	display: flex;
	margin-bottom: 10px;
	input {
		margin-left: 10px;
	}
}

.invoice-items-row {
	padding: 0 20px;
}

.fake-link {
	text-decoration: underline;
	cursor: pointer;
}
.deposit-form-label {
	margin: 10px 10px;
	font-weight: bold;
	font-size: 18px;
}

.header-refunds {
	margin-top: 20px;
}

.header-block {
	// border-right: solid 2px gray;
	text-align: center;
}
.deposit-form-container:not(.item-0) {
	border-top: 1px solid gray;
}

.admin-item-info-field {
	&.toggle-invoice-link {
		margin: 10px 0;

		.invoice-info {
			margin-left: 20px;
		}
	}
}
.cancel-requested-text {
	line-height: 20px;
	color: $red;
	font-weight: bold;
}
</style>
