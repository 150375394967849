<template>
	<div>
		<Loading v-if="loading" />
		<div class="admin-parking">
			<div class="admin-parking-info-header">
				<h2 class="admin-parking-info-header-title">
					{{
						formOpen
							? parking.id
								? $t('admin.parking.updateParkingLabel')
								: $t('admin.parking.createParkingLabel')
							: $t('profile.myParkings.title')
					}}
				</h2>
				<button
					:class="{ blue: !formOpen, red: formOpen }"
					class="button admin-parking-info-header-button"
					@click="toggleForm"
				>
					{{ formOpen ? $t('general.cancelLabel') : $t('admin.parking.createParkingLabel') }}
				</button>
			</div>
			<form
				v-if="formOpen"
				class="admin-parking-form"
				data-vv-scope="parking-form"
				autocomplete="off"
				@submit.prevent="validateFormBeforeSubmit"
			>
				<div class="input-box admin-parking-form-field">
					<label class="profile-input-field-label">{{
						$t('profile.myParkings.formLabels.name')
					}}</label>
					<input
						v-model="parking.name"
						v-validate="{ required: true }"
						:placeholder="$t('profile.myParkings.formLabels.name')"
						:class="{ error: errors.has('parking-form.parkingName') }"
						:data-vv-as="$t('admin.parking.name')"
						name="parkingName"
						type="text"
						class="input-field"
					/>
					<p v-show="errors.has('parking-form.parkingName')" class="input-error">
						{{ errors.first('parking-form.parkingName') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field">
					<label class="profile-input-field-label">{{
						$t('profile.myParkings.formLabels.address')
					}}</label>
					<input
						v-model="parking.legacyAddressNl"
						v-validate="{ required: true }"
						:placeholder="`${$t('profile.myParkings.formLabels.address')} (NL)`"
						:class="{ error: errors.has('parking-form.legacyAddressNl') }"
						:data-vv-as="$t('admin.parking.addressLabel')"
						name="legacyAddressNl"
						type="text"
						class="input-field profile-input-field"
					/>
					<p v-show="errors.has('parking-form.legacyAddressNl')" class="input-error">
						{{ errors.first('parking-form.legacyAddressNl') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.legacyAddressFr"
						v-validate="{ required: true }"
						:placeholder="`${$t('profile.myParkings.formLabels.address')} (FR)`"
						:class="{ error: errors.has('parking-form.legacyAddressFr') }"
						:data-vv-as="$t('admin.parking.addressLabel')"
						name="legacyAddressFr"
						type="text"
						class="input-field profile-input-field"
					/>
					<p v-show="errors.has('parking-form.legacyAddressFr')" class="input-error">
						{{ errors.first('parking-form.legacyAddressFr') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field multiple">
					<div class="admin-parking-form-field-box">
						<label class="profile-input-field-label">{{
							$t('profile.myParkings.formLabels.latitude')
						}}</label>
						<input
							v-model="parking.latitude"
							v-validate="{ required: true, regex: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/ }"
							:class="{ erraor: errors.has('parking-form.latitude') }"
							:placeholder="$t('profile.myParkings.formLabels.latitude')"
							data-vv-as="Latitude"
							name="latitude"
							type="text"
							class="input-field inline profile-input-field"
						/>
						<p v-show="errors.has('parking-form.latitude')" class="input-error">
							{{ errors.first('parking-form.latitude') }}
						</p>
					</div>
					<div class="admin-parking-form-field-box">
						<label class="profile-input-field-label">{{
							$t('profile.myParkings.formLabels.longitude')
						}}</label>
						<input
							v-model="parking.longitude"
							v-validate="{
								required: true,
								regex: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
							}"
							:class="{ error: errors.has('parking-form.longitude') }"
							:placeholder="$t('profile.myParkings.formLabels.longitude')"
							data-vv-as="Longitude"
							name="longitude"
							type="text"
							class="input-field inline profile-input-field"
						/>
						<p v-show="errors.has('parking-form.longitude')" class="input-error">
							{{ errors.first('parking-form.longitude') }}
						</p>
					</div>
				</div>

				<div class="input-box admin-parking-form-field">
					<label class="profile-input-field-label">{{
						$t('profile.myParkings.formLabels.comment')
					}}</label>
					<input
						v-model="parking.commentNl"
						:placeholder="`${$t('profile.myParkings.formLabels.comment')} (NL)`"
						name="commentNl"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.commentFr"
						:placeholder="`${$t('profile.myParkings.formLabels.comment')} (FR)`"
						name="commentFr"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.commentEn"
						:placeholder="`${$t('profile.myParkings.formLabels.comment')} (EN)`"
						name="commentEn"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>

				<div class="input-box admin-parking-form-field multiple">
					<div class="admin-parking-form-field-box">
						<label class="profile-input-field-label">{{
							$t('profile.myParkings.formLabels.classic')
						}}</label>
						<input
							v-model="parking.capacityClassic"
							v-validate="{ required: true, numeric: true }"
							:placeholder="$t('admin.parking.classic')"
							:class="{ error: errors.has('parking-form.classic') }"
							:data-vv-as="$t('admin.parking.classic')"
							name="classic"
							type="text"
							class="input-field inline profile-input-field"
							@keyup="capacityChanged('Standard', parking.capacityClassic)"
						/>
						<p v-show="errors.has('parking-form.classic')" class="input-error">
							{{ errors.first('parking-form.classic') }}
						</p>
					</div>
					<div class="admin-parking-form-field-box">
						<label class="profile-input-field-label">{{
							$t('profile.myParkings.formLabels.cargo')
						}}</label>
						<input
							v-model="parking.capacityCargo"
							v-validate="{ required: true, numeric: true }"
							:placeholder="$t('admin.parking.cargo')"
							:class="{ error: errors.has('parking-form.cargo') }"
							:data-vv-as="$t('admin.parking.cargo')"
							name="cargo"
							type="text"
							class="input-field inline profile-input-field"
							@keyup="capacityChanged('Cargo', parking.capacityCargo)"
						/>
						<p v-show="errors.has('parking-form.cargo')" class="input-error">
							{{ errors.first('parking-form.cargo') }}
						</p>
					</div>
				</div>
				<h2>Parking spot prices:</h2>
				<ParkingSpot
					v-for="(parkingSpot, index) in parking.parkingSpots"
					:key="index"
					:parkingSpot.sync="parking.parkingSpots[index]"
					:sIndex="index"
					:parkingSpotErrors="parkingSpotErrors"
					@removeSpot="removeParkingSpot($event)"
				/>
				<div class="input-box admin-parking-form-field">
					<div class="button blue notLong" @click="addParkingSpot()">Add Price</div>
				</div>
				<Checkbox v-model="parking.active" class="admin-parking-form-checkbox" name="active">
					<span slot="label" class="checkbox-text text">
						{{ $t('profile.myParkings.formLabels.isActive') }}
					</span>
				</Checkbox>

				<div class="admin-parking-form-actions">
					<button
						:disabled="errors.any('parking-form')"
						:class="{ disabled: errors.any('parking-form') }"
						class="button blue"
						type="submit"
					>
						{{
							parking.id
								? $t('profile.myParkings.formLabels.update')
								: $t('profile.myParkings.formLabels.create')
						}}
					</button>
				</div>
			</form>
			<div v-else class="admin-parking-search">
				<section>
					<div class="admin-item-row">
						<div class="admin-item-col small header">
							{{ $t('profile.myParkings.tableHeaders.code') }}
						</div>
						<div class="admin-item-col small header">
							{{ $t('profile.myParkings.tableHeaders.name') }}
						</div>
						<div class="admin-item-col small header">
							{{ $t('profile.myParkings.tableHeaders.active') }}
						</div>
						<div class="admin-item-col smaller header">
							{{ $t('profile.myParkings.tableHeaders.approved') }}
						</div>
					</div>
					<MyParkingResult
						v-for="(parking, index) in myParkings"
						:key="index"
						:parking="parking"
						@on-click="editParking($event)"
					/>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import MyParkingResult from '@/components/profile/MyParkingResult.vue'
import ValidationRequired from '@/mixins/validation-required'
import ParkingSpot from '@/components/admin/parking/ParkingSpot.vue'
import { find, omit } from 'lodash/fp'

// import { logger } from '@/logger'

export default {
	name: 'MyParkings',
	components: {
		Loading,
		Checkbox,
		MyParkingResult,
		ParkingSpot,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			initialParkingState: {
				name: '',
				code: '',
				legacyAddressNl: '',
				legacyAddressFr: '',
				active: false,
				parkingSpots: [],
				isCycloparking: false,
				badgeAccess: false,
				longitude: '',
				latitude: '',
				installationDate: new Date().getTime(),
			},
			parking: {},
			formOpen: false,
			parkingSpotErrors: {},
		}
	},
	computed: {
		...mapState('profile', {
			currentUser: (state) => state.currentUser,
		}),
		...mapState({
			myParkings: (state) => state.profile.myParkings.all,
			loading: (state) => state.profile.loading,
		}),
	},
	mounted() {
		// log current user
		this.$validator.pause()
		// this.getMyParkings()
		// wait for this.currentUser to be set
		this.$watch('currentUser', (newVal) => {
			if (newVal) {
				this.getMyParkings(newVal.id)
			}
		})
	},
	methods: {
		...mapActions({
			getMyParkings: 'profile/getMyParkings',
		}),
		...mapActions('profile', {
			upsertMyParking: 'upsertMyParking',
		}),

		validateFormBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll('parking-form').then(async (result) => {
				// this.addressTypeError = await this.parking.parkingTypeName.key && await this.parking.keyType.key

				let ok = true

				if (!this.validateParkingSpots()) {
					ok = false
				}

				if (result && ok) {
					this.$validator.pause()

					if (await this.upsertMyParking(this.parking)) {
						this.formOpen = false
					}
				}
			})
		},

		toggleForm() {
			if (!this.formOpen) {
				this.parking = this.initialParkingState
			}

			this.formOpen = !this.formOpen
		},

		editParking(id) {
			const editParking = find({ id }, this.myParkings)

			this.parking = omit(
				['legacyAddress', 'comment', '__typename'],
				Object.assign(
					{},
					editParking,
					{
						legacyAddressNl: editParking.legacyAddress.nl,
						legacyAddressFr: editParking.legacyAddress.fr,
					},
					{
						commentNl: editParking.comment.nl,
						commentFr: editParking.comment.fr,
						commentEn: editParking.comment.en,
					}
				)
			)
			this.formOpen = true
		},
		addParkingSpot() {
			this.parking.parkingSpots.push({ active: true })
		},
		removeParkingSpot(index) {
			this.parking.parkingSpots = this.parking.parkingSpots.filter((s, i) => i !== index)
			this.parkingSpotErrors[index] = {}
		},
		validateParkingSpots() {
			let ok = true

			this.parkingSpotErrors = {}

			const needClassicSpot = this.parking.capacityClassic && this.parking.capacityClassic > 0
			const needCargoSpot = this.parking.capacityCargo && this.parking.capacityCargo > 0
			let hasClassicSpot = false
			let hasCargoSpot = false

			this.parking.parkingSpots.forEach((s, i) => {
				this.parkingSpotErrors[i] = {}

				if (s.active) {
					if (s.isCargo) {
						hasCargoSpot = true

						if (!needCargoSpot) {
							this.parkingSpotErrors[i].amount =
								"You can't have a cargo price if you have no cargo spots"
							ok = false
						}
					} else {
						hasClassicSpot = true

						if (!needClassicSpot) {
							this.parkingSpotErrors[i].amount =
								"You can't have a classic price if you have no classic spots"
							ok = false
						}
					}
				}

				if (!s.amount) {
					this.parkingSpotErrors[i].amount = 'Price is Mandatory'
					ok = false
				}

				if (!s.name) {
					this.parkingSpotErrors[i].name = 'Description is Mandatory'
					ok = false
				}
			})

			if (needCargoSpot && !hasCargoSpot) {
				this.parking.parkingSpots.push({ name: 'Cargo', isCargo: true, active: true })

				const newSpotI = this.parking.parkingSpots.length - 1

				this.parkingSpotErrors[newSpotI] = { amount: 'You need at least one price for Cargo' }
				ok = false
			}

			if (needClassicSpot && !hasClassicSpot) {
				this.parking.parkingSpots.push({ name: 'Standard', isCargo: false, active: true })

				const newSpotI = this.parking.parkingSpots.length - 1

				this.parkingSpotErrors[newSpotI] = { amount: 'You need at least one price for Classic' }
				ok = false
			}

			return ok
		},
		addParkingSpotIfNotYet(name) {
			const isCargo = name === 'Cargo'
			const existing = this.parking.parkingSpots.find((s) => {
				return isCargo ? s.isCargo : !s.isCargo
			})

			if (!existing) {
				this.parking.parkingSpots.push({ name, isCargo, active: true })
			}
		},
		capacityChanged(name, nbr) {
			if (nbr && nbr > 0) {
				this.addParkingSpotIfNotYet(name)
			}
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminParking';
@import '../../assets/scss/pages/admin';
@import '../../assets/scss/pages/profile/index';
.mx-datepicker {
	width: 100%;
}
.mx-input-append {
	width: 35px;
	right: 5px;
}
.admin-item-col.header {
	line-height: 20px;
}
</style>
