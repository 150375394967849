import APIClient from '../api/apiClient'

const DepositServices = {
	//Service to refund new key
	refundNewKeyForSubscription: (payload) => {
		return APIClient.post(`/deposits/refund-new-key`, payload)
	},
	// /api/v1/deposits/close-deposit
	//Service to close deposit
	closeDeposit: (payload) => {
		return APIClient.post(`/deposits/close-deposit`, payload)
	},
}

export default DepositServices
