<template>
	<div class="admin-item-row clickable" @click="$emit('on-click', session.id)">
		<div class="admin-item-col medium">
			{{ parseInt(session.sessionId) }}
		</div>
		<div class="admin-item-col medium">
			<div v-if="session.sessionUser">{{ session.sessionUser.firstName }} {{ session.sessionUser.lastName }}</div>
		</div>
		<div class="admin-item-col small">
			{{ session.bikes || 0 }}
		</div>
		<div class="admin-item-col small">
			{{ session.sessionParking ? session.sessionParking.code : '-' }}
		</div>
		<div class="admin-item-col small">
			{{ time(session.startTimeInMs) }}
		</div>
		<div class="admin-item-col small">
			{{ diffInTime(session.startTimeInMs, session.endTimeInMs || new Date().getTime()) }}
		</div>
		<div class="admin-item-col small">
			<button class="button red" @click="cancelSession(session.id)">Cancel</button>
		</div>
	</div>
</template>

<script>
import { time, diffInTime } from '@/helpers'
export default {
	name: 'SessionResult',
	components: {},
	props: {
		session: {
			type: Object,
			default: () => ({}),
		},
		cancelSession: {
			type: Function,
		},
	},
	data() {
		return {}
	},

	computed: {},
	methods: {
		time,
		diffInTime,
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
}
</style>
