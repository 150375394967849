<template>
	<div>
		<Loading v-if="!isLoaded || isLoading" />
		<PreviousButton />
		<div class="sms-validation">
			<h1 class="title sms-validation-title">
				{{ $t('emailValidation.title') }}
			</h1>
			<form
				v-show="currentUser.verificationId"
				class="sms-validation-form"
				data-vv-scope="code-form"
				@submit.prevent=""
			>
				<p class="text sms-validation-info">
					{{ $t('emailValidation.requestInfo') + ' [' + currentUser.phoneNumberTemp + ']' }}
				</p>
				<div class="input-box sms-validation-otp-inputs-sms">
					<input
						v-for="(input, index) in inputs"
						:key="index"
						v-model="input.value"
						@input="handleInput(index, $event)"
						@keyup="handleKeyUp(index, $event)"
						maxlength="1"
						ref="inputs"
						:class="{ 'last-input': index === inputs.length - 1, error: input.error }"
						class="sms-validation-otp-input-field input-field"
						type="tel"
						inputmode="numeric"
						pattern="[0-9]*"
					/>
					<p v-if="errors.has('code-form.accessCode')" class="input-error">
						{{ errors.first('code-form.accessCode') }}
					</p>
				</div>
				<div>
					<span class="sms-validation-link-button-link" @click="doRequestToken()">
						{{ $t('emailValidation.resendCode') }}
					</span>
				</div>
				<button
					:disabled="errors.has('code-form.accessCode') || smsCode.length < 4"
					:class="{ disabled: errors.has('code-form.accessCode') || smsCode.length < 4 }"
					class="button red sms-validation-button-confirm"
					@click="doValidateToken"
				>
					{{ $t('smsValidation.validateLabel') }}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
// import { toggleReveal } from '@/helpers'
import ValidationRequired from '@/mixins/validation-required'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'SmsVerifyPhoneOtp',
	components: {
		Loading,
		PreviousButton,
	},
	mixins: [ValidationRequired],
	props: {
		verification: {
			default: true,
			type: Boolean,
		},
		from: {
			String: true,
		},
	},
	data() {
		return {
			loading: false,
			smsCode: '',
			smsCode: '',
			inputs: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],

			phoneNumber: '',
			localErrors: {
				phone: '',
			},
			local: {
				phoneNumber: '',
			},
			passwordConfirmation: {
				value: this.$route.params?.password || '',
				reveal: false,
			},
			telDoptions: { showDialCodeInSelection: true, showFlags: true },
			telIoptions: { showDialCode: true },
			open: false,
			// localUser: Object.assign({}, this.currentUser),
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.authentication.isLoading,
			alert: (state) => state.alert,
			showTelForm: (state) => state.authentication.showTelForm,
		}),
		...mapGetters({
			isLoaded: 'profile/isLoaded',
		}),

		localUser() {
			return Object.assign({}, this.currentUser)
		},
	},
	created() {
		this.loadCurrentUser().then(() => this.setLocalPhone())
		// if (!this.isLoaded) {
		// 	this.loadCurrentUser().then(() => this.setLocalPhone())
		// } else {
		// 	this.setLocalPhone()
		// }
	},
	mounted() {
		// Auto focus first input on component mount
		this.$nextTick(() => {
			if (this.$refs.inputs && this.$refs.inputs[0]) {
				this.$refs.inputs[0].focus()
			}
		})
	},
	methods: {
		...mapActions({
			loadCurrentUser: 'profile/getCurrentUser',
			validateToken: 'authentication/validateToken',
			requestToken: 'authentication/requestToken',
		}),
		async doValidateToken() {
			await this.validateToken(this.smsCode)
			// this.flash(this.alert.message, this.alert.type)
		},
		async doRequestToken() {
			// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
			// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
			// This way, we make sure that the phone number is always correct.
			this.phoneNumber = this.currentUser.phoneNumberTemp
			await this.requestToken({
				phoneNumber: this.phoneNumber,
				password: this.passwordConfirmation.value,
			})

			// this.flash(this.alert.message, this.alert.type)
		},
		hasLocalErrors() {
			return Boolean(this.localErrors.phone) || (this.phoneNumber || '').length < 1
		},
		// onInputPhone(value, phoneObject) {
		// 	this.local.phoneNumber = phoneObject.number

		// 	const empty = (value || '').length < 1

		// 	this.localErrors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		// },

		setLocalPhone() {
			this.phoneNumber = this.currentUser.phoneNumber || this.currentUser.phoneNumberTemp || ''
		},
		doShowTelForm() {
			this.$store.commit('authentication/setShowTelForm', true)
		},
		// doToggleReveal(field) {
		// 	this[field].reveal = toggleReveal(this[field], this.$refs[field])
		// },
		handleInput(index, event) {
			const val = event.target.value
			if (isNaN(val)) {
				this.inputs[index].value = ''
				return
			}
			if (val !== '') {
				if (this.smsCode.length < 4) {
					// this.smsCode += String(val)
					this.smsCode = this.inputs.map((input) => input.value).join('')
					console.log('this.smsCode', this.smsCode)
				}
				const nextIndex = index + 1
				if (this.inputs[nextIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[nextIndex].focus()
					})
				}
			}
		},
		handleKeyUp(index, event) {
			const key = event.key.toLowerCase()
			if (key === 'backspace' || key === 'delete') {
				this.inputs[index].value = ''
				if (this.smsCode.length > 0) {
					this.smsCode = this.smsCode.slice(0, -1)
				}
				const prevIndex = index - 1
				if (this.inputs[prevIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[prevIndex].focus()
					})
				}
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			if (!vm.isLoaded) {
				await vm.loadCurrentUser()
			}
			if (to.params.password === undefined) {
				next({ name: 'profile' })
			} else {
				// Otherwise, continue with the route
				next()
			}
		})
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/smsValidation';
</style>
