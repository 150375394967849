<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="coupon" />
		<div class="admin-coupon">
			<h2 class="admin-parking-info-header-title">{{ $t('admin.coupon.searchCoupon') }}</h2>
			<form
				class="admin-coupon-form"
				data-vv-scope="coupon-search-form"
				autocomplete="off"
				@submit.prevent="searchForm()"
			>
				<div class="admin-coupon-search-content">
					<div class="admin-coupon-search-field">
						<input
							ref="coupon-search"
							v-model="couponQuery"
							:placeholder="$t('admin.coupon.couponCode')"
							:class="{ error: hasError('coupon-search-form', 'search') }"
							name="search"
							type="text"
							class="input-field admin-input-field input admin-search-field"
						/>
						<p v-show="hasError('coupon-search-form', 'search')" class="input-error">
							{{ getError('coupon-search-form', 'search') }}
						</p>
					</div>
					<button
						:disabled="errors.any('coupon-search-form')"
						:class="{ disabled: errors.any('coupon-search-form') }"
						class="button blue admin-parking-search-button"
						type="submit"
					>
						{{ $t('admin.searchButtonText') }}
					</button>
				</div>
			</form>
			<div class="admin-coupon-actions">
				<div
					:class="{ active: formOpen }"
					class="button blue admin-parking-info-header-button activatable"
					@click="toggleForm"
				>
					{{ formOpen ? $t('general.cancelLabel') : $t('admin.coupon.add') }}
				</div>
			</div>
			<form
				v-if="formOpen"
				class="admin-coupon-add-form"
				data-vv-scope="admin-coupon-add-form"
				autocomplete="off"
				@submit.prevent="validateBeforeSubmit()"
			>
				<div class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.name')}*` }}</label>
						<div class="input-box admin-parking-form-field">
							<input
								v-model="coupon.name"
								v-validate="{ required: true, min: 3 }"
								:placeholder="$t('admin.coupon.name')"
								:class="{ error: hasError('admin-coupon-add-form', 'name', 'coupon') }"
								name="coupon.name"
								type="text"
								class="input-field profile-input-field"
							/>
						</div>
						<p v-show="hasError('admin-coupon-add-form', 'name', 'coupon')" class="input-error">
							{{ getError('admin-coupon-add-form', 'name', 'coupon') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label" style="min-height: 17px"> </label>
						<Checkbox
							v-model="coupon.isPercentage"
							class="profile-checkbox"
							name="isPercentage"
							:disabled="editMode"
						>
							<span slot="label" class="checkbox-text text">
								{{ $t('admin.coupon.isPercentage') }}
							</span>
						</Checkbox>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${
								coupon.isPercentage ? $t('admin.coupon.amountPercent') : $t('admin.coupon.amount')
							}*`
						}}</label>
						<div class="input-box admin-parking-form-field">
							<input
								v-model="coupon.amount"
								v-validate="{ required: true, numeric: true }"
								:placeholder="
									coupon.isPercentage ? $t('admin.coupon.amountPercent') : $t('admin.coupon.amount')
								"
								:class="{ error: hasError('admin-coupon-add-form', 'amount', 'coupon') }"
								:disabled="editMode"
								name="coupon.amount"
								type="number"
								class="input-field profile-input-field"
							/>
						</div>
						<p v-show="hasError('admin-coupon-add-form', 'amount', 'coupon')" class="input-error">
							{{ getError('admin-coupon-add-form', 'amount', 'coupon') }}
						</p>
					</div>
				</div>
				<div class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${$t('admin.coupon.parkingType')}*`
						}}</label>
						<v-select
							v-model="coupon.parkingTypes"
							multiple
							label="value"
							:placeholder="$t('admin.coupon.parkingType')"
							:class="{ error: hasError('admin-coupon-add-form', 'parkingType', 'coupon') }"
							:options="subscriptionTypeNames"
							:reduce="(option) => option.key"
							@option:selected="parkingTypeSelected"
						>
							<template #open-indicator="{}">
								<span></span>
							</template>
						</v-select>
						<p
							v-show="hasError('admin-coupon-add-form', 'parkingType', 'coupon')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'parkingType', 'coupon') }}
						</p>
					</div>
				</div>
				<div class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.parking')}` }}</label>
						<v-select
							v-model="coupon.parkingIds"
							multiple
							:placeholder="$t('admin.coupon.parking')"
							:class="{ error: hasError('admin-coupon-add-form', 'parkings', 'coupon') }"
							:options="orderedParkings"
							:reduce="(option) => option.id"
						>
							<!-- @option:selected="parkingSelected" -->
							<template #open-indicator="{}">
								<span></span>
							</template>
							<template #selected-option="{ code }">
								<span>{{ code }}</span>
							</template>
						</v-select>
						<p v-show="hasError('admin-coupon-add-form', 'parkings', 'coupon')" class="input-error">
							{{ getError('admin-coupon-add-form', 'parkings', 'coupon') }}
						</p>
					</div>
				</div>
				<div class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.duration')}*` }}</label>
						<Dropdown
							:value="coupon.duration.value"
							:options="durationOptions"
							:selected="coupon.duration"
							:placeholder="$t('admin.coupon.duration')"
							:class="{ error: hasError('admin-coupon-add-form', 'duration', 'coupon') }"
							:disabled="editMode"
							name="coupon.duration"
							class="profile-input-field small"
							@on-click="(option) => selectDuration(option.key)"
						/>
					</div>
					<p v-show="hasError('admin-coupon-add-form', 'duration', 'coupon')" class="input-error">
						{{ getError('admin-coupon-add-form', 'duration', 'coupon') }}
					</p>
					<div class="admin-coupon-add-form-row-item">
						<template v-if="coupon.duration && coupon.duration.value === 'repeating'">
							<label class="profile-input-field-label">{{
								`${$t('admin.coupon.durationMonths')}*`
							}}</label>
							<div class="input-box admin-parking-form-field">
								<input
									v-model="coupon.nbrMonths"
									v-validate="{ numeric: true }"
									:placeholder="$t('admin.coupon.durationMonths')"
									:class="{ error: hasError('admin-coupon-add-form', 'nbrMonths', 'coupon') }"
									:disabled="editMode"
									name="coupon.nbrMonths"
									type="number"
									class="input-field profile-input-field"
								/>
							</div>
							<p
								v-show="hasError('admin-coupon-add-form', 'nbrMonths', 'coupon')"
								class="input-error"
							>
								{{ getError('admin-coupon-add-form', 'nbrMonths', 'coupon') }}
							</p>
						</template>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label" style="min-height: 17px"> </label>
						<Checkbox
							v-model="coupon.active"
							class="profile-checkbox"
							name="coupon.active"
							:class="{ error: hasError('admin-coupon-add-form', 'active', 'coupon') }"
						>
							<span slot="label" class="checkbox-text text">
								{{ $t('admin.coupon.active') }}
							</span>
						</Checkbox>
						<p v-show="hasError('admin-coupon-add-form', 'active', 'coupon')" class="input-error">
							{{ getError('admin-coupon-add-form', 'active', 'coupon') }}
						</p>
					</div>
				</div>
				<div class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.from')}*` }}</label>
						<div class="input-box admin-parking-form-field">
							<date-picker
								v-model="coupon.validFromDate"
								name="coupon.validFromDate"
								format="DD-MM-YYYY"
								:lang="$i18n.locale"
								:class="{ error: hasError('admin-coupon-add-form', 'validFromDate', 'coupon') }"
							></date-picker>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'validFromDate', 'coupon')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'validFromDate', 'coupon') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.to')}` }}</label>
						<div class="input-box admin-parking-form-field">
							<date-picker
								v-model="coupon.expirationDate"
								format="DD-MM-YYYY"
								name="coupon.expirationDate"
								:lang="$i18n.locale"
								:class="{ error: hasError('admin-coupon-add-form', 'expirationDate', 'coupon') }"
							></date-picker>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'expirationDate', 'coupon')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'expirationDate', 'coupon') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.uses')}` }}</label>
						<div class="input-box admin-parking-form-field">
							<input
								v-model="coupon.maxNbrRedemptions"
								:placeholder="$t('admin.coupon.uses')"
								name="coupon.maxNbrRedemptions"
								type="number"
								class="input-field profile-input-field small"
								:class="{ error: hasError('admin-coupon-add-form', 'maxNbrRedemptions', 'coupon') }"
							/>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'maxNbrRedemptions', 'coupon')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'maxNbrRedemptions', 'coupon') }}
						</p>
					</div>
				</div>
				<hr />
				<!-- Coupon Codes -->
				<div v-if="coupon.couponCodes.length > 0" class="admin-coupon-add-form-row">
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{
							`${$t('admin.coupon.couponCode')}`
						}}</label>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.from')}*` }}</label>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.to')}` }}</label>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<label class="profile-input-field-label">{{ `${$t('admin.coupon.uses')}` }}</label>
					</div>
					<div class="admin-coupon-add-form-row-item tiny">
						<label class="profile-input-field-label">ACT</label>
					</div>
					<div class="admin-coupon-add-form-row-item tiny">
						<label class="profile-input-field-label"></label>
					</div>
				</div>
				<div v-else class="admin-coupon-add-form-row">NO CODES DEFINED YET</div>
				<div
					v-for="(code, idx) in coupon.couponCodes"
					:key="code.id"
					class="admin-coupon-add-form-row"
				>
					<div class="row-number">{{ idx + 1 }}</div>
					<div class="admin-coupon-add-form-row-item">
						<div class="input-box admin-parking-form-field">
							<input
								v-model="code.name"
								:placeholder="'optional'"
								:class="{ error: hasError('admin-coupon-add-form', 'name', 'couponCode') }"
								name="couponCode.name"
								type="text "
								class="input-field profile-input-field small"
							/>
							<p
								v-show="hasError('admin-coupon-add-form', 'name', 'couponCode')"
								class="input-error"
							>
								{{ getError('admin-coupon-add-form', 'name', 'couponCode') }}
							</p>
						</div>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<div class="input-box admin-parking-form-field">
							<date-picker
								v-model="code.validFromDate"
								name="couponCode.validFromDate"
								format="DD-MM-YYYY"
								:lang="$i18n.locale"
								:class="{ error: hasError('admin-coupon-add-form', 'validFromDate', 'couponCode') }"
							></date-picker>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'validFromDate', 'couponCode')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'validFromDate', 'couponCode') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<div class="input-box admin-parking-form-field">
							<date-picker
								v-model="code.expirationDate"
								name="couponCode.expirationDate"
								format="DD-MM-YYYY"
								:lang="$i18n.locale"
								:class="{
									error: hasError('admin-coupon-add-form', 'expirationDate', 'couponCode'),
								}"
							></date-picker>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'expirationDate', 'couponCode')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'expirationDate', 'couponCode') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item">
						<div class="input-box admin-parking-form-field small">
							<input
								v-model="code.maxNbrRedemptions"
								:placeholder="$t('admin.coupon.uses')"
								name="couponCode.maxNbrRedemptions"
								type="number"
								class="input-field profile-input-field small"
								:class="{
									error: hasError('admin-coupon-add-form', 'maxNbrRedemptions', 'couponCode'),
								}"
							/>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'maxNbrRedemptions', 'couponCode')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'maxNbrRedemptions', 'couponCode') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item tiny">
						<div class="input-box admin-parking-form-field small">
							<Checkbox
								v-model="code.active"
								class="profile-checkbox small"
								name="couponCode.active"
								:class="{ error: hasError('admin-coupon-add-form', 'active', 'couponCode') }"
							>
							</Checkbox>
						</div>
						<p
							v-show="hasError('admin-coupon-add-form', 'active', 'couponCode')"
							class="input-error"
						>
							{{ getError('admin-coupon-add-form', 'active', 'couponCode') }}
						</p>
					</div>
					<div class="admin-coupon-add-form-row-item tiny">
						<div class="input-box admin-parking-form-field small">
							<div
								v-if="!code.id"
								class="button-delete code-button-delete small red icon-actions"
								@mousedown="removeCode($event, idx)"
								@keyup.enter="removeCode($event, idx)"
							/>
						</div>
					</div>
				</div>
				<div class="admin-coupon-add-form-row actions">
					<button class="button orange small" @click="addCode($event)">Add code</button>
					<button
						:disabled="errors.any('admin-coupon-add-form')"
						:class="{ disabled: errors.any('admin-coupon-add-form') || hasCustomError }"
						class="button blue admin-parking-search-button"
						type="submit"
					>
						{{ coupon.id ? $t('general.saveLabel') : $t('general.addLabel') }}
					</button>
				</div>
			</form>
			<div class="admin-coupon-table">
				<section>
					<header class="admin-item-row header">
						<div class="admin-item-col header">Coupon name</div>
						<div class="admin-item-col header">From</div>
						<div class="admin-item-col header">To</div>
						<div class="admin-item-col header small">Duration</div>
						<div class="admin-item-col header tiny">%|€</div>
						<div class="admin-item-col header tiny">#</div>
						<div class="admin-item-col header tiny">Act?</div>
						<div class="admin-item-col header tiny">Val?</div>
						<div class="admin-item-col header"></div>
					</header>
					<div v-for="item in visibleCoupons" :key="item.id">
						<div
							class="admin-item-row clickable"
							:class="{ selected: item.id === selectedCoupon }"
							@click="showCodes(item.id)"
						>
							<div class="admin-item-col">
								{{ item.name }}
							</div>
							<div class="admin-item-col">
								{{ date(item.validFromDate) }}
							</div>
							<div class="admin-item-col">
								{{ date(item.expirationDate) }}
							</div>
							<div class="admin-item-col small">
								{{ item.durationValue }}
							</div>
							<div class="admin-item-col tiny">
								{{ `${item.amount} ${item.isPercentage ? '%' : '€'}` }}
							</div>
							<div class="admin-item-col tiny">
								{{ item.nbrRedeemed }}
							</div>
							<div class="admin-item-col tiny">
								{{ item.active ? 'Y' : 'N' }}
							</div>
							<div class="admin-item-col tiny">
								{{ item.valid ? 'Y' : 'N' }}
							</div>
							<div class="admin-item-col">
								<button class="button tiny blue edit" @click="editCoupon(item.id, $event)"></button>
							</div>
						</div>
						<template v-if="item.id === selectedCoupon">
							<div class="admin-item-row coupon-code-row">
								<div class="admin-item-col header medium">CODE</div>
								<div class="admin-item-col header medium">FROM</div>
								<div class="admin-item-col header medium">TO</div>
								<div class="admin-item-col header"># redeemed</div>
								<div class="admin-item-col header tiny">ACT</div>
								<div class="admin-item-col header tiny">VAL</div>
							</div>
							<div
								v-for="code in item.couponCodes"
								:key="code.id"
								class="admin-item-row coupon-code-row"
							>
								<div class="admin-item-col medium">
									{{ code.name }}
								</div>
								<div class="admin-item-col medium">
									{{ date(code.validFromDate) }}
								</div>
								<div class="admin-item-col medium">
									{{ date(code.expirationDate) }}
								</div>
								<div class="admin-item-col">
									{{ code.nbrRedeemed }}
								</div>
								<div class="admin-item-col tiny">
									{{ code.active ? 'Y' : 'N' }}
								</div>
								<div class="admin-item-col tiny">
									{{ code.valid ? 'Y' : 'N' }}
								</div>
							</div>
						</template>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import Dropdown from '@/components/form/Dropdown'
import DatePicker from 'vue2-datepicker'
import Checkbox from '@/components/form/Checkbox.vue'
import ValidationRequired from '@/mixins/validation-required'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { initialCouponState } from '@/store/modules/admin/coupon'
import { date } from '@/helpers'
import { omit, filter, some } from 'lodash/fp'

// import { initialPcPriceState } from '@/store/modules/admin/municipality'
// import { find, filter, omit } from 'lodash/fp'

// import { logger } from '@/logger'

export default {
	name: 'AdminCoupon',
	components: {
		Loading,
		Menu,
		Dropdown,
		DatePicker,
		Checkbox,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			customErrors: {},
			hasCustomError: false,
			coupon: {},
			visibleCoupons: [],
			selectedCoupon: 0,
			durationOptions: [
				{ key: 'repeating', value: 'repeating' },
				{ key: 'once', value: 'once', ignoreField: 'nbrMonths' },
				{ key: 'forever', value: 'forever', ignoreField: 'nbrMonths' },
			],
			couponQuery: '',
			formOpen: false,
			editMode: false,
		}
	},
	computed: {
		...mapState('admin', {
			allCoupons: (state) => state.coupons.all,
			backendErrors: (state) => state.coupons.error,
			loading: (state) => state.loading,
		}),

		...mapGetters('admin', {
			subscriptionTypeNames: 'getsubscriptionTypeNames',
			orderedParkings: 'couponParkings',
			keyTypes: 'getKeyTypes',
		}),
	},
	mounted() {
		this.$validator.pause()
		this.getParkings()
		this.getSubscriptionTypeNames()
		this.getCoupons().then(() => {
			this.visibleCoupons = this.allCoupons
		})

		// 	.then(() => {
		// 	this.visiblePcPrices = this.allPcPrices
		// })
		this.coupon = Object.assign({}, initialCouponState)
	},
	methods: {
		...mapActions('admin', {
			getParkings: 'getParkings',
			getSubscriptionTypeNames: 'getSubscriptionTypeNames',
			getCoupons: 'getCoupons',
			upsertCoupon: 'upsertCoupon',
		}),
		...mapMutations('admin', {
			resetCouponError: 'resetCouponError',
		}),
		searchForm() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll('coupon-search-form').then((result) => {
				if (result) {
					this.$validator.pause()
					this.visibleCoupons = this.findCoupons(this.couponQuery)
				}
			})
		},
		hasFormError(formName, fieldName) {
			return this.errors.has(`${formName}.${fieldName}`)
		},
		getBackendError(couponOrCode, fieldName) {
			return couponOrCode && this.backendErrors[couponOrCode][fieldName]
		},
		hasError(formName, fieldName, type = null) {
			const fullFieldName = type ? `${type}.${fieldName}` : fieldName

			return (
				this.hasFormError(formName, fullFieldName) ||
				this.getCustomError(fullFieldName) ||
				this.getBackendError(type, fieldName)
			)
		},
		getFormError(formName, fieldName) {
			return this.hasFormError(formName, fieldName) && this.errors.first(`${formName}.${fieldName}`)
		},
		getCustomError(fieldName) {
			return this.hasCustomError && this.customErrors[fieldName]
		},
		getError(formName, fieldName, type = null) {
			const fullFieldName = type ? `${type}.${fieldName}` : fieldName

			return (
				this.getFormError(formName, fullFieldName) ||
				this.getCustomError(fullFieldName) ||
				this.getBackendError(type, fieldName)
			)
		},
		findCoupons(query) {
			const re = new RegExp(query, 'i')

			return filter(
				(coupon) =>
					coupon.name.search(re) >= 0 || some((c) => c.name.search(re) >= 0, coupon.couponCodes),
				this.allCoupons
			)
		},
		toggleForm() {
			if (!this.formOpen) {
				this.coupon = { ...initialCouponState, duration: {}, parkingTypes: [] }
				this.customErrors = {}
				this.hasCustomError = false
				this.resetCouponError()
			}

			this.editMode = false

			this.formOpen = !this.formOpen
		},

		showCodes(id) {
			this.selectedCoupon = id
		},

		validateBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			if (!this.loading) {
				this.resetCouponError()
				this.$validator.validateAll('admin-coupon-add-form').then(async (result) => {
					let ok = true

					if (this.coupon.parkingTypes.length === 0) {
						this.customErrors['coupon.parkingType'] = 'Required'
						this.hasCustomError = true
						ok = false
					}

					if (!this.coupon.duration.key) {
						this.customErrors['coupon.duration'] = 'Required'
						this.hasCustomError = true
						ok = false
					}

					// if (result && !this.addressTypeError) {
					if (result && ok) {
						this.$validator.pause()

						if (await this.upsertCoupon(this.coupon)) {
							this.formOpen = false
							this.editMode = false
						}
					}
				})
			}
		},

		editCoupon(id, e) {
			this.$validator.reset()
			this.resetCouponError()

			const coupon = this.allCoupons.find((c) => c.id === id)

			this.coupon = omit(['__typename'], Object.assign({}, coupon))
			this.coupon.parkingTypes = this.coupon.couponParkingTypes.map((pt) => pt.parkingTypeName)
			this.selectDuration(coupon.durationValue)

			this.customErrors = {}
			this.hasCustomError = false
			this.formOpen = true
			this.editMode = true
			e.stopPropagation()
		},

		addCode(e) {
			this.coupon.couponCodes.push({ validFromDate: this.coupon.validFromDate, active: true })
			e.preventDefault()
		},

		removeCode(e, idx) {
			if (e.which === 1 || e.button === 0) {
				this.coupon.couponCodes.splice(idx, 1)
				e.preventDefault()
			}
		},

		selectDuration(optionKey) {
			if (this.customErrors['coupon.duration']) {
				delete this.customErrors['coupon.duration']

				if (Object.keys(this.customErrors).length < 1) {
					this.hasCustomError = false
				}
			}

			this.coupon.duration = this.durationOptions.find((opt) => opt.key === optionKey)

			if (this.coupon.duration.ignoreField) {
				this.coupon[this.coupon.duration.ignoreField] = null
			}
		},

		parkingTypeSelected() {
			if (this.customErrors['coupon.parkingType']) {
				delete this.customErrors['coupon.parkingType']

				if (Object.keys(this.customErrors).length < 1) {
					this.hasCustomError = false
				}
			}
		},

		date,
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/admin/coupon';
@import '../../assets/scss/components/vue-select';
// @import '~/vue2-datepicker/scss/index.scss';
</style>
