import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/user/Login.vue'
import Signup from './views/user/Signup.vue'
import Profile from './views/user/Profile.vue'
import Subscription from './views/user/Subscription.vue'
import TemporaryAccess from './views/user/TemporaryAccess.vue'
import MollieBilling from './views/user/MollieBilling'
import ForgotPassword from './views/user/ForgotPassword'
import ResetPassword from './views/user/ResetPassword.vue'
import RenewRequest from './views/user/RenewRequest.vue'
// import SmsValidation from './views/user/SmsValidation'
import smsValidationSubscribe from './views/user/smsValidationSubscribe.vue'
import SmsVerifyPhoneOtp from './views/user/SmsVerifyPhoneOtp'
import EmailValidation from './views/user/EmailValidation'
import ForgotPasswordValidation from './views/user/ForgotPasswordValidation'
import Admin from './views/admin/Admin.vue'
import Attribution from './views/admin/Attribution.vue'
import Parkings from './views/user/Parkings.vue'
import MyParkings from './views/user/MyParkings.vue'
import AdminParking from './views/admin/Parking.vue'
import AdminSession from './views/admin/Session.vue'
import AdminExport from './views/admin/Export.vue'
import Municipality from './views/admin/Municipality.vue'
import Payment from './views/user/Payment.vue'
import Coupon from './views/admin/Coupon.vue'
import AccountMenu from './components/profile/AccountMenu.vue'
import { some } from 'lodash/fp'
import { setChildToken } from '@/store/modules/profile'
import HowItWorks from './views/user-session/HowItWorks.vue'
import HowItPay from './views/user-session/HowItPay'
import HowSessionStart from './views/user-session/HowSessionStart'
import Qr2 from './views/user-session/Qr2.vue'
import SessionStartQr from './views/user-session/SessionStartQr'
import MainDoorQr from './views/user-session/MainDoorQr'
import MainDoorExtQr from './views/user-session/MainDoorExtQr'
import EnteringQr from './views/user-session/EnteringQr'
import SessionEndQr from './views/user-session/SessionEndQr'
import SeeYouSoon from './views/user-session/SeeYouSoon'
import { APP_ENV_SESSION } from '@/config'
import i18n from '@/i18nVeeValidate'
import PageNotFound from './views/user-session/Pagenotfound.vue'

Vue.use(Router)

const titlePrefix = 'CycloParking'

// Add this utility function
const updateDocumentTitle = (titleKey) => {
	if (titleKey) {
		document.title = `${titlePrefix} - ${i18n.t(titleKey)}`
	} else {
		document.title = titlePrefix
	}
}

const sessionRoutes = APP_ENV_SESSION
	? [
			{
				path: '/how-it-pay',
				name: 'HowItPay',
				component: HowItPay,
				meta: { titleKey: 'router.titles.HowItPay' },
			},
			{
				path: '/how-session-start',
				name: 'HowSessionStart',
				component: HowSessionStart,
				meta: { titleKey: 'router.titles.HowSessionStart' },
			},
			{
				path: '/main-door-qr',
				name: 'MainDoorQr',
				component: MainDoorQr,
				meta: { titleKey: 'router.titles.MainDoorQr' },
			},
			{
				path: '/main-door-exit-qr',
				name: 'MainDoorExtQr',
				component: MainDoorExtQr,
				meta: { titleKey: 'router.titles.MainDoorQr' },
			},
			{
				path: '/session-start-qr',
				name: 'SessionStartQr',
				component: SessionStartQr,
				meta: { titleKey: 'router.titles.SessionStartQr' },
			},
			{
				path: '/qr-2',
				name: 'Qr2',
				component: Qr2,
				meta: { titleKey: 'router.titles.Qr2' },
			},

			{
				path: '/entering-qr',
				name: 'EnteringQr',
				component: EnteringQr,
				meta: { titleKey: 'router.titles.EnteringQr' },
			},
			{
				path: '/session-end-qr',
				name: 'SessionEndQr',
				component: SessionEndQr,
				meta: { titleKey: 'router.titles.SessionEndQr' },
			},
			{
				path: '/see-you-soon',
				name: 'SeeYouSoon',
				component: SeeYouSoon,
				meta: { titleKey: 'router.titles.SessionDetail' },
			},
			{
				path: '/session/:sessionId',
				name: 'SessionDetail',
				component: SeeYouSoon,
				meta: { titleKey: 'router.titles.SessionDetail' },
			},
			{
				path: '/session/:sessionId/thankyou',
				name: 'SessionDetail',
				component: SeeYouSoon,
				meta: { titleKey: 'router.titles.SessionDetail' },
			},
			{
				path: '/not-found',
				name: 'PageNotFound',
				component: PageNotFound,
				meta: { titleKey: 'router.titles.SessionDetail' },
			},
	  ]
	: []

const router = new Router({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/profile',
			name: 'profile',
			component: Profile,
			meta: { titleKey: 'router.titles.profile' },
			alias: '/',
			props: (r) => ({
				editable: Boolean(r.query.edit),
				proposal: r.query.proposal,
			}),
		},
		{
			path: '/verifyPhone',
			name: 'verifyPhone',
			component: SmsVerifyPhoneOtp,
			props: true,
			meta: { titleKey: 'router.titles.verifyPhone' },
		},
		{
			path: '/smsValidation',
			name: 'smsValidation',
			component: smsValidationSubscribe,
			props: true,
			meta: { titleKey: 'router.titles.smsValidation' },
		},
		{
			path: '/emailValidation',
			name: 'emailValidation',
			component: EmailValidation,
			props: true,
			meta: { titleKey: 'router.titles.emailValidation' },
		},
		{
			path: '/forgotPasswordValidation',
			name: 'ForgotPasswordValidation',
			component: ForgotPasswordValidation,
			props: true,
			meta: { titleKey: 'router.titles.forgotPasswordValidation' },
		},
		{
			path: '/accountMenu',
			name: 'accountMenu',
			component: AccountMenu,
			props: true,
			meta: { titleKey: 'router.titles.accountMenu' },
		},
		{
			path: '/signup/:subType?',
			name: 'signup',
			component: Signup,
			meta: { titleKey: 'router.titles.signup' },
		},
		{
			path: '/login/:subType?',
			name: 'login',
			component: Login,
			meta: { titleKey: 'router.titles.login' },
		},
		{
			path: '/payment',
			name: 'payment',
			component: Payment,
			meta: { titleKey: 'router.titles.payment' },
		},
		{
			path: '/subscription/:subType?',
			name: 'subscription',
			component: Subscription,
			meta: { titleKey: 'router.titles.subscription' },
			props: (r) => ({
				step: r.query.step,
				subId: Number(r.query.subId),
				subType: r.params.subType,
				proposalId: Number(r.query.proposal),
			}),
		},
		{
			path: '/temporaryAccess/:parkingCode?',
			name: 'temporaryAccess',
			component: TemporaryAccess,
			meta: { titleKey: 'router.titles.temporaryAccess' },
			props: (r) => ({
				parkingCode: r.params.parkingCode,
				temporaryAccessId: Number(r.query.id),
			}),
		},
		{
			path: '/forgotPassword',
			name: 'forgotPassword',
			component: ForgotPassword,
			meta: { titleKey: 'router.titles.forgotPassword' },
		},
		{
			path: '/resetPassword/:code?',
			name: 'resetPassword',
			component: ResetPassword,
			meta: { titleKey: 'router.titles.resetPassword' },
		},
		{
			path: '/renewRequest/:code?',
			name: 'renewRequest',
			component: RenewRequest,
			meta: { titleKey: 'router.titles.renewRequest' },
		},
		{
			path: '/parkings',
			name: 'parkings',
			component: Parkings,
			meta: { titleKey: 'router.titles.parkings' },
			props: (r) => ({
				focus: Number(r.params.focus),
			}),
		},
		{
			path: '/myparkings',
			name: 'myparkings',
			component: MyParkings,
			meta: { titleKey: 'router.titles.myparkings' },
		},
		{
			path: '/go/:subType',
			redirect: '/signup/:subType',
		},
		{
			path: '/claim',
			redirect: '/profile',
		},
		{
			path: '/mollie-billing',
			name: 'MollieBilling',
			component: MollieBilling,
			meta: { titleKey: 'router.titles.MollieBilling' },
		},
		{
			path: '*',
			redirect: '/',
		},

		// parking session
		{
			path: '/how-it-works',
			name: 'howitworks',
			component: HowItWorks,
			meta: { titleKey: 'router.titles.howitworks' },
		},
		...sessionRoutes,

		// Admin routes
		{
			path: '/admin',
			name: 'admin',
			component: Admin,
			meta: { titleKey: 'router.titles.admin', admin: true },
		},
		{
			path: '/attribution',
			name: 'attribution',
			component: Attribution,
			meta: { titleKey: 'router.titles.attribution', admin: true },
		},
		{
			path: '/adminParking',
			name: 'adminParking',
			component: AdminParking,
			meta: { titleKey: 'router.titles.adminParking', admin: true },
		},
		{
			path: '/adminExport',
			name: 'adminExport',
			component: AdminExport,
			meta: { titleKey: 'router.titles.adminExport', admin: true },
		},
		{
			path: '/municipality',
			name: 'municipality',
			component: Municipality,
			meta: { titleKey: 'router.titles.municipality', admin: true },
		},
		{
			path: '/coupon',
			name: 'coupon',
			component: Coupon,
			meta: { titleKey: 'router.titles.coupon', admin: true },
		},
		{
			path: '/adminSession',
			name: 'adminSession',
			component: AdminSession,
			meta: { titleKey: 'router.titles.adminSession', admin: true },
		},
	],
})

router.beforeEach((to, from, next) => {
	// Redirect to login page if not logged in and trying to access a restricted page
	const publicPages = [
		'/login',
		'/signup',
		'/forgotPassword',
		'/resetPassword',
		'/parkings',
		'/renewRequest',
		'/how-it-works',
		// "/forgotPasswordValidation",
	]

	const authRequired = !some((page) => new RegExp(page).test(to.path), publicPages)
	const loggedIn = localStorage.getItem('cycloToken')

	// Set page title using translation
	updateDocumentTitle(to.meta.titleKey)

	// eslint-disable-next-line no-debugger
	// debugger

	if (to.query.family) {
		setChildToken(to.query.family)

		return next({ ...to, query: { ...to.query, family: undefined } })
	}

	if (authRequired && !loggedIn) {
		return next({ name: 'login', query: { from: to.fullPath } })
	}

	// if (loggedIn && !authRequired) {
	// 	return next('/')
	// }

	return next()
})

// Add a new watcher for i18n locale changes
i18n.watchLocale = () => {
	const route = router.currentRoute
	if (route.meta.titleKey) {
		updateDocumentTitle(route.meta.titleKey)
	}
}

export default router
