import APIClient from '../api/apiClient'

const RequestsService = {
	// Service to get all requests
	getRequests: (params) => {
		return APIClient.get(`/requests/all-requests`, { params })
	},

	// Service to get request by URL code
	getRequestByUrlCode: (payload) => {
		return APIClient.post(`/requests/by-url-code`, payload)
	},

	// Service to get request by ID
	getRequestById: (requestid) => {
		return APIClient.get(`/requests/request-operations/${requestid}`)
	},

	// Service to update request by ID
	updateRequestById: (requestid, payload) => {
		return APIClient.put(`/requests/request-operations/${requestid}`, payload)
	},
	//Service for creating a new request
	createRequest: (payload) => {
		return APIClient.post(`/requests/request-operations`, payload)
	},
	//Service for cancelling a request / deleting a request
	cancelRequest: (requestid) => {
		return APIClient.post(`/requests/request-operations/${requestid}/cancel-parking-request`)
	},
	//Service to delete a request
	deleteParkingRequest: (requestid) => {
		return APIClient.delete(`/requests/request-operations/${requestid}`)
	},
	//Service to get request by renew code
	getRequestByRenewCode: (payload) => {
		return APIClient.post(`/requests/by-url-code`, payload)
	},
	//Service to cancel request by renew code
	cancelRequestByRenewCode: (payload) => {
		return APIClient.post(`/requests/cancel-by-renew-code`, payload)
	},
	//Service to cancel request by ID
	cancelParkingRequest: (requestid) => {
		return APIClient.post(`/requests/request-operations/${requestid}/cancel-parking-request`)
	},
}

export default RequestsService
