<template>
	<div class="profile-form-content">
		<!-- <div v-if="!isChild" class="profile-child-account">
			<div
				class="button blue profile-child-account-button back-button"
				tabindex="0"
				@click="backToParent"
			>
				{{ $t('profile.backToParent') }}
			</div>
		</div> -->
		<Loading v-if="editLoading || loading" />
		<h1 v-if="!isChild" class="profile-edit-account-title title">
			{{ $t('profile.infoEditTitle') }}
		</h1>
		<h1 v-if="isChild && isChildByAdmin" class="profile-edit-account-title title">Famille</h1>
		<form
			action
			class="profile-edit-form"
			data-vv-scope="profile-form"
			@submit.prevent="validateBeforeSubmit"
		>
			<label class="">{{ $t('profile.firstNameLabel') }}*</label>
			<input
				v-model="profileLocal.firstName"
				v-validate="{ regex: '^[^0-9]+$' }"
				:placeholder="$t('profile.firstNameLabel')"
				:class="{ error: errors.has('profile-form.firstName') }"
				:data-vv-as="$t('profile.firstNameLabel')"
				name="firstName"
				type="text"
				class="input-field profile-input-field input"
			/>
			<p v-show="errors.has('profile-form.firstName')" class="input-error">
				{{ errors.first('profile-form.firstName') }}
			</p>
			<label class="">{{ $t('profile.lastNameLabel') }}*</label>
			<input
				v-model="profileLocal.lastName"
				v-validate="{ regex: '^[^0-9]+$' }"
				:placeholder="$t('profile.lastNameLabel')"
				:class="{ error: errors.has('profile-form.lastName') }"
				:data-vv-as="$t('profile.lastNameLabel')"
				name="lastName"
				type="text"
				class="input-field profile-input-field"
			/>
			<p v-show="errors.has('profile-form.lastName')" class="input-error">
				{{ errors.first('profile-form.lastName') }}
			</p>

			<!-- <Dropdown
				v-if="!isChild"
				:value="local.language || profileLocal.language"
				:selected="findCurrentLanguage()"
				:options="languages"
				:placeholder="$t('signup.languageLabel')"
				class="profile-language-selection"
				@on-click="(option) => selectOption('language', option)"
			/> 
			<div v-if="!isChild && !isAdminEdit" class="profile-input-action-box">
				<div class="input-field profile-input-field disabled">
					{{ profile.email }}
				</div>
				 <div
					:class="{ active: emailFormOpen }"
					class="button red activatable profile-action-button"
					tabindex="0"
					@mousedown="toggleEmailForm($event)"
					@keyup.enter="toggleEmailForm($event)"
				>
					{{ emailFormOpen ? $t('general.cancelLabel') : $t('general.editLabel') }}
				</div> 
			</div> -->
			<label v-if="isAdminEdit" class="">{{ $t('profile.emailLabel') }}</label>
			<div v-if="isAdminEdit" class="profile-input-action-box">
				<input
					v-model="profileLocal.email"
					v-validate="isChildByAdmin ? '' : 'required|email'"
					:placeholder="$t('profile.emailLabel')"
					:class="{ error: errors.first('email') }"
					name="email"
					class="input-field profile-input-field"
					:type="isChildByAdmin ? 'text' : 'email'"
				/>
				<button
					:disabled="errors.first('email')"
					class="button red activatable profile-action-button"
					type="button"
					@click="doUpdateEmail()"
				>
					{{ $t('general.editLabel') }}
				</button>
			</div>

			<form
				v-if="!isChild && !isAdminEdit && !showTelForm"
				class="profile-form"
				data-vv-scope="email-form"
				@submit.prevent="validateEmailBeforeSubmit"
			>
				<Loading v-if="emailLoading" positionClass="absolute" />
				<div class="input-disabled-label">
					{{ profile.email }}
				</div>
				<div class="input-box profile-email-input-box">
					<label class="profile-input-field-label">{{ $t('profile.emailLabel') }}</label>
					<input
						ref="email"
						v-model="profileLocal.email"
						v-validate="'required|email'"
						:placeholder="$t('profile.emailLabel')"
						:class="{ error: errors.has('email-form.email') }"
						:data-vv-as="$t('profile.emailLabel')"
						name="email"
						class="input-field profile-input-field"
						type="text"
					/>
				</div>
				<p v-show="errors.has('email-form.email')" class="input-error">
					{{ errors.first('email-form.email') }}
				</p>

				<div class="input-box profile-password-input-box">
					<label class="profile-input-field-label">{{ $t('profile.confirmPassword') }}</label>
					<input
						ref="passwordConfirmation"
						v-model="local.passwordConfirmation.value"
						v-validate="'required|min:6'"
						:placeholder="$t('profile.confirmPassword')"
						:class="{ error: errors.has('email-form.password') }"
						:data-vv-as="$t('profile.confirmPassword')"
						name="password"
						type="password"
						class="input-field profile-input-field"
					/>
					<div
						class="input-reveal-button with-label"
						tabindex="0"
						@click="doToggleReveal('passwordConfirmation')"
						@keyup.enter="doToggleReveal('passwordConfirmation')"
					>
						<div
							:class="{ revealed: local.passwordConfirmation.reveal }"
							class="input-reveal-button-line"
						/>
					</div>
					<p v-show="errors.has('email-form.password')" class="input-error">
						{{ errors.first('email-form.passwpord') }}
					</p>
				</div>
				<button
					:disabled="errors.any('email-form')"
					:class="{ disabled: errors.any('email-form') }"
					class="button red"
					type="submit"
				>
					{{ $t('profile.updateEmail') }}
				</button>
			</form>
			<form
				v-if="showTelForm"
				class="sms-validation-form"
				data-vv-scope="code-form"
				@submit.prevent=""
			>
				<p class="text sms-validation-info">
					{{ $t('emailValidation.requestInfo') }}
					<strong>
						{{ profile.email }}
					</strong>
				</p>
				<div class="sms-validation-otp-inputs-sms">
					<input
						v-for="(input, index) in inputs"
						:key="index"
						v-model="input.value"
						@input="handleInput(index, $event)"
						@keyup="handleKeyUp(index, $event)"
						maxlength="1"
						ref="inputs"
						:class="{ 'last-input': index === inputs.length - 1, error: input.error }"
						class="sms-validation-otp-input-field input-field"
						type="text"
						inputmode="numeric"
						pattern="[0-9]*"
						:autofocus="index === 0"
					/>
					<p v-if="errors.has('code-form.accessCode')" class="input-error">
						{{ errors.first('code-form.accessCode') }}
					</p>
				</div>
				<div>
					<span class="sms-validation-link-button-link" @click="doRequestToken()">
						{{ $t('emailValidation.resendCode') }}
					</span>
				</div>

				<button
					:disabled="errors.has('code-form.accessCode') || smsCode.length < 4"
					:class="{ disabled: errors.has('code-form.accessCode') || smsCode.length < 4 }"
					class="button red sms-validation-button-confirm"
					@click="doValidateToken"
				>
					{{ $t('emailValidation.validateLabel') }}
				</button>
			</form>

			<!-- <div v-if="!isChild && !isAdminEdit" class="profile-input-action-box">
				<div class="input-field profile-input-field disabled">
					*{{ $t('profile.yourPassword') }}*
				</div>
				<div
					:class="{ active: passwordFormOpen }"
					class="button red activatable profile-action-button"
					tabindex="0"
					@mousedown="togglePasswordForm($event)"
					@keyup.enter="togglePasswordForm($event)"
				>
					{{ passwordFormOpen ? $t('general.cancelLabel') : $t('general.editLabel') }}
				</div>
			</div> -->
			<form
				v-if="!isChild && !isAdminEdit"
				class="profile-form"
				data-vv-scope="password-form"
				@submit.prevent="validatePasswordBeforeSubmit"
			>
				<Loading v-if="passwordLoading" />
				<div class="input-box profile-password-input-box">
					<label class="profile-input-field-label">{{ $t('profile.oldPassword') }}</label>
					<input
						ref="oldPassword"
						v-model="local.oldPassword.value"
						v-validate="'required|min:6'"
						:placeholder="$t('profile.oldPassword')"
						:class="{ error: errors.has('password-form.oldPassword') }"
						:data-vv-as="$t('profile.oldPassword')"
						name="oldPassword"
						type="password"
						class="input-field profile-input-field"
					/>
					<div class="input-reveal-button with-label" @click="doToggleReveal('oldPassword')">
						<div :class="{ revealed: local.oldPassword.reveal }" class="input-reveal-button-line" />
					</div>
					<p v-show="errors.has('password-form.oldPassword')" class="input-error">
						{{ errors.first('password-form.oldPassword') }}
					</p>
				</div>
				<div class="input-box profile-password-input-box">
					<label class="profile-input-field-label">{{ $t('profile.newPassword') }}</label>
					<input
						ref="newPassword"
						v-model="local.newPassword.value"
						v-validate="'required|min:6'"
						:placeholder="$t('profile.newPassword')"
						:class="{ error: errors.has('password-form.newPassword') }"
						:data-vv-as="$t('profile.newPassword')"
						name="newPassword"
						type="password"
						class="input-field profile-input-field"
					/>
					<div class="input-reveal-button with-label" @click="doToggleReveal('newPassword')">
						<div :class="{ revealed: local.newPassword.reveal }" class="input-reveal-button-line" />
					</div>
					<p v-show="errors.has('password-form.newPassword')" class="input-error">
						{{ errors.first('password-form.newPassword') }}
					</p>
				</div>
				<button
					:disabled="errors.any('password-form')"
					:class="{ disabled: errors.any('password-form') }"
					class="button red"
					type="submit"
				>
					{{ $t('profile.updatePassword') }}
				</button>
			</form>
			<SmsValidation v-if="!isChild && !isAdminEdit" />
			<!-- <div v-if="!isChild && !isAdminEdit" class="profile-input-action-box">
				<div class="input-field profile-input-field disabled">
					{{ profileLocal.phoneNumber }}
				</div>
				<router-link
					:to="{ name: 'smsValidation', params: { verification: false } }"
					class="activatable button red profile-action-button"
				>
					{{ $t('general.editLabel') }}
				</router-link>
			</div> -->

			<p v-show="!!localErrors.phone" class="input-error">
				{{ localErrors.phone }}
			</p>
			<!-- <Dropdown
				v-if="!isChild"
				:value="local.language || profileLocal.language"
				:selected="findCurrentLanguage()"
				:options="languages"
				:placeholder="$t('signup.languageLabel')"
				class="profile-language-selection"
				@on-click="(option) => selectOption('language', option)"
			/> -->
			<div class="input-box signup-input" v-if="!isChild">
				<label class="profile-field-title">
					<span>{{ $t('signup.languageLabel') }}</span>
				</label>
				<div class="signup-radio">
					<span
						v-for="(language, index) in SignupLanguages"
						:key="index"
						class="signup-radio-group"
					>
						<input
							class="signup-radio-group-input"
							type="radio"
							v-model="profile.language"
							:value="language.key"
							:checked="language.value === findCurrentLanguage().value"
							@change="selectOption('language', language)"
						/>
						<span class="custom-radio"></span>
						<span :for="language.code" class="signup-language-radio-label">
							<img :src="require(`../../assets/images/flags/${language.icon}`)" />
							{{ language.value }}
						</span>
					</span>
				</div>
				<p v-if="localErrors.language" class="input-error">
					{{ localErrors.language }}
				</p>
			</div>

			<label class="profile-field-title">
				{{ $t('profile.mobibLabelEdit') }}
			</label>
			<div v-if="!isAdminEdit" class="profile-input-action-box">
				<MobibInput
					:mobib.sync="profileLocal.mobib"
					:mobibPresent="Boolean(profile.mobib)"
					:message="$t('profile.mobibInfo')"
					errorTag="profile-form.mobib"
					class="profile-mobib-field"
				/>
				<button
					v-if="!!profile.mobib"
					class="button grey profile-action-button"
					type="button"
					@click="$emit('delete-mobib')"
				>
					{{ $t('profile.deleteMobibLabel') }}
				</button>
			</div>
			<div v-else class="profile-input-action-box">
				<cleave
					ref="mobibField"
					v-model="profileLocal.mobib"
					v-validate="{ regex: '^(?=[0-9A-Fa-f]*$)(?:.{16}|.{18})$' }"
					:placeholder="$t('profile.mobibLabel')"
					:data-vv-as="$t('profile.mobibLabel')"
					:options="cleaveOptions"
					name="mobib"
					type="text"
					class="input-field profile-input-field"
				/>
			</div>
			<p v-show="errors.has('profile-form.mobib')" class="input-error">
				{{ errors.first('profile-form.mobib') }}
			</p>
			<label v-if="!isChildByAdmin" class="profile-field-title">{{
				$t('profile.bankAccount.title')
			}}</label>
			<div v-if="!isChildByAdmin" class="profile-input-action-box">
				<input
					v-model="profileLocal.bankAccount"
					:placeholder="$t('profile.bankAccount.title')"
					name="bankAccount"
					type="text"
					class="input-field profile-input-field"
				/>

				<button
					class="button red profile-action-button"
					type="button"
					@click="doUpdateBankAccount()"
				>
					{{ $t('general.editLabel') }}
				</button>
			</div>

			<div v-if="isAdminEdit">
				<button class="button red" type="button" @click="doSendResetLink()">
					{{ $t('admin.sendResetLink') }}
				</button>
				<!-- TODO Check if this is working -->
				<div v-if="profile.resetLink" class="password-reset-link">
					{{ profile.resetLink }}
				</div>
			</div>

			<!-- <form
				v-if="profile.existsInStripe && !isAdminEdit"
				action
				class="profile-updatePayment"
				data-vv-scope="update-payment-method-form"
				@submit.prevent="submitUpdatePaymentMethod"
			>
				<div class="profile-updatePayment-line">
					<span class="profile-updatePayment-line-text">
						{{ $t('profile.currentPaymentMethod') }}
						<span class="profile-updatePayment-line-value">
							{{ displayPaymentMethod($t(`profile.paymentMethods.${profile.paymentMethod}`)) }}
						</span>
					</span>
					<button class="button orange profile-updatePayment-line-button" type="submit">
						{{ $t('profile.updatePaymentMethod') }}
					</button>
				</div>
			</form>
			<Checkbox
				v-if="!isChild && !isChildByAdmin"
				v-model="profileLocal.newsletter"
				class="profile-checkbox"
				name="newsletter"
			>
				<span slot="label" class="checkbox-text text">{{ $t('signup.newsletterLabel') }}</span>
			</Checkbox>
			-->
			<div v-if="!isChild && !isChildByAdmin">
				<p
					class="link activatable profile-action-button company-toggle"
					tabindex="0"
					@mousedown="toggleCompanyForm($event)"
					@keyup.enter="toggleCompanyForm($event)"
				>
					{{ companyFormOpen ? $t('profile.company.closeForm') : $t('profile.company.openForm') }}
				</p>

				<ProfileCompany
					v-if="companyFormOpen"
					ref="company"
					:company.sync="profileLocal.company"
					:customErrors="formErrors"
					@removeCustomError="doRemoveCustomError"
				/>
			</div>
			<button
				:disabled="errors.any('profile-form') || anyLocalErrors()"
				:class="{ disabled: errors.any('profile-form') || anyLocalErrors() }"
				class="button red shadow profile-action-button"
				type="submit"
			>
				{{ $t('profile.updateProfileLabel') }}
			</button>
		</form>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { isEmpty } from 'lodash/fp'
import Dropdown from '@/components/form/Dropdown.vue'
import Loading from '@/components/Loading.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import MobibInput from '@/components/form/MobibInput.vue'
import ProfileCompany from '@/components/profile/Company.vue'
import { languages } from '@/config'
import { toggleReveal } from '@/helpers'
import SmsValidation from '../../views/user/SmsValidation.vue'
import { SignupLanguages } from '@/config'

/* import { logger } from '@/logger' */

export default {
	name: 'ProfileEdit',
	components: {
		Dropdown,
		Loading,
		Checkbox,

		// Info,
		MobibInput,
		ProfileCompany,
		SmsValidation,
	},
	props: {
		profile: {
			type: Object,
			default: () => ({}),
		},
		editLoading: {
			type: Boolean,
			default: false,
		},
		isChild: {
			type: Boolean,
			default: false,
		},
		isAdminEdit: {
			type: Boolean,
			default: false,
		},
		createAccountOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			SignupLanguages,
			languages,
			local: {
				language: '',
				oldPassword: {
					value: '',
					reveal: false,
				},
				newPassword: {
					value: '',
					reveal: false,
				},
				passwordConfirmation: {
					value: '',
					reveal: false,
				},
			},
			localErrors: {},
			profileLocal: Object.assign({}, this.profile),
			passwordFormOpen: false,
			emailFormOpen: false,
			passwordLoading: false,
			emailLoading: false,
			companyFormOpen: false,
			loading: false,
			inputs: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
			smsCode: '',
		}
	},
	computed: {
		...mapState({
			alert: (state) => state.alert,
			showTelForm: (state) => state.authentication.showTelForm,
		}),

		...mapGetters('profile', {
			formErrors: 'getFormErrors',
		}),
		cleaveOptions() {
			if (this.profile.mobib && this.profile.mobib.length > 1 && this.profile.mobib[1] === '0') {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [4, 4, 4, 4, 2], uppercase: true }
			} else {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [2, 4, 4, 4, 4], uppercase: true }
			}
		},
		isChildByAdmin() {
			return Boolean(this.profile.parentId)
		},
	},
	watch: {
		// make sure mobib field gets emptied after hitting delete button
		'profile.mobib'(newVal) {
			this.profileLocal.mobib = newVal
		},
	},
	mounted() {
		// console.log('isChild', this.isChild, 'isChildByAdmin', this.isChildByAdmin)
	},
	methods: {
		...mapActions({
			// clearProfileError: 'alert/clear',
		}),
		...mapActions('authentication', {
			updatePassword: 'updatePassword',
			validateToken: 'validateEmailToken',
			requestToken: 'requestEmailToken',
		}),
		...mapActions('profile', {
			updateEmail: 'updateEmail',
			updatePaymentMethod: 'updatePaymentMethod',
		}),
		...mapMutations('profile', {
			removeProfileError: 'removeProfileError',
			removeProfileErrors: 'removeProfileErrors',
		}),
		...mapActions('admin', {
			updateEmailForUser: 'updateEmailForUser',
			updateMobibForUser: 'updateMobibForUser',
			updateBankAccountForUser: 'updateBankAccountForUser',
			sendResetLink: 'sendResetLink',
		}),
		async doValidateToken() {
			await this.validateToken(Number(this.smsCode))

			// this.flash(this.alert.message, this.alert.type)
		},
		async doRequestToken() {
			await this.requestToken({
				email: this.profileLocal.email,
				password: this.local.passwordConfirmation.value,
			})

			// this.flash(this.alert.message, this.alert.type)
		},

		selectOption(field, option) {
			this.localErrors[field] = ''
			this.local[field] = option.key
		},
		findCurrentLanguage() {
			return languages.find((l) => l.key === (this.local.language || this.profileLocal.language))
		},
		validateBeforeSubmit() {
			this.removeProfileErrors()
			this.$validator.validateAll('profile-form').then((result) => {
				if (result && !this.anyLocalErrors()) {
					this.$emit(
						'update',
						Object.assign({}, this.profileLocal, {
							language: this.local.language || this.profileLocal.language,
							mobib: this.profileLocal.mobib || '',
						})
					)
				}
			})
		},
		async submitUpdatePaymentMethod() {
			const url = await this.updatePaymentMethod()

			window.location.href = url
		},

		async validatePasswordBeforeSubmit() {
			this.passwordLoading = true

			const validated = await this.$validator.validateAll('password-form')

			if (validated) {
				const updated = await this.updatePassword({
					newPassword: this.local.newPassword.value,
					oldPassword: this.local.oldPassword.value,
				})

				if (updated) {
					this.togglePasswordForm()
				}
			}

			this.passwordLoading = false
		},
		async validateEmailBeforeSubmit() {
			this.emailLoading = true

			const validated = await this.$validator.validateAll('email-form')

			if (validated) {
				const updated = await this.updateEmail({
					email: this.profileLocal.email,
					password: this.local.passwordConfirmation.value,
				})

				if (updated) {
					this.toggleEmailForm()
					this.$store.commit('authentication/setShowTelForm', true)
				}
			}

			this.emailLoading = false
		},
		doToggleReveal(field) {
			this.local[field].reveal = toggleReveal(this.local[field], this.$refs[field])
		},

		anyLocalErrors() {
			if (isEmpty(this.localErrors)) {
				return false
			} else {
				return Object.keys(this.localErrors).every((key) => {
					return Boolean(this.localErrors[key])
				})
			}
		},
		togglePasswordForm(e) {
			if (e) {
				e.preventDefault()
			}

			this.passwordFormOpen = !this.passwordFormOpen

			if (this.passwordFormOpen) {
				this.emailFormOpen = false
			}
		},
		toggleCompanyForm(e) {
			if (e) {
				e.preventDefault()
			}

			this.companyFormOpen = !this.companyFormOpen
		},
		toggleEmailForm(e) {
			if (e) {
				e.preventDefault()
			}

			this.emailFormOpen = !this.emailFormOpen

			if (this.emailFormOpen) {
				this.passwordFormOpen = false
			}
		},
		displayPaymentMethod(value) {
			const knowedPaymentMethods = ['card', 'bancontact', 'none']
			const paymentMethod = this.profile.paymentMethod

			if (knowedPaymentMethods.includes(paymentMethod)) {
				return value
			} else {
				return paymentMethod
			}
		},
		doRemoveCustomError(error) {
			this.removeProfileError(error)
		},
		async doUpdateEmail() {
			this.loading = true
			await this.updateEmailForUser({ email: this.profileLocal.email, userId: this.profile.id })
			this.loading = false
		},
		async doUpdateBankAccount() {
			console.log('isAdminEdit', this.isAdminEdit)
			const result = await this.updateBankAccountForUser({
				bankAccount: this.profileLocal.bankAccount,
				userId: this.profile.id,
			})
			if (result && !this.isAdminEdit) {
				this.profile.bankAccount = result.updateBankAccountForUser
			}
		},
		doSendResetLink() {
			if (confirm('Are you sure you want to send a password reset link to this user?')) {
				this.resetLink = this.sendResetLink({ userId: this.profile.id })
			}
		},
		handleInput(index, event) {
			const val = event.target.value
			if (isNaN(val)) {
				this.inputs[index].value = ''
				return
			}
			if (val !== '') {
				if (this.smsCode.length < 4) {
					// this.smsCode += String(val)
					this.smsCode = this.inputs.map((input) => input.value).join('')
					console.log('this.smsCode', this.smsCode)
				}
				const nextIndex = index + 1
				if (this.inputs[nextIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[nextIndex].focus()
					})
				}
			}
		},
		handleKeyUp(index, event) {
			const key = event.key.toLowerCase()
			if (key === 'backspace' || key === 'delete') {
				this.inputs[index].value = ''
				if (this.smsCode.length > 0) {
					this.smsCode = this.smsCode.slice(0, -1)
				}
				const prevIndex = index - 1
				if (this.inputs[prevIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[prevIndex].focus()
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
@import '../../assets/scss/pages/signup';
.company-toggle {
	cursor: pointer;
	color: #3c3c3b;
	font-family: 'Poppins Bold';
}
.company-toggle:after {
	background-color: $middle-grey;
}
</style>
