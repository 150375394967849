import APIClient from '../api/apiClient'

const ProposalsService = {
	getProposals: (params) => {
		return APIClient.get(`/proposals/all-proposals`, { params })
	},
	getPropsalbyId: (proposalid) => {
		return APIClient.get(`/proposals/proposal-operations/${proposalid}`)
	},
	createPropsal: (payload) => {
		return APIClient.post(`/proposals/proposal-operations`, payload)
	},
	rejectProposal: (payload) => {
		return APIClient.post(`/proposals/reject-proposal` , payload)
	},
	// /api/v1/proposals/proposal-operations/{proposalid}/cancel
	cancelProposal: (proposalid) => {
		return APIClient.post(`/proposals/proposal-operations/${proposalid}/cancel`)
	},
}
export default ProposalsService
