<template>
	<div class="bg">
		<Loading v-if="sessionActive.length === 0 || isLoading" />
		<activesession
			@openModal="openModal"
			:sessionActive="sessionActive"
			:totalAmountPaid="totalAmountPaid"
			v-else="sessionActive.length > 0"
		/>
		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h1>{{ modalContent.title }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ modalContent.description }}
				</p>
				<div>
					<div class="alert-message">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert">{{ modalContent.alertText }}</span>
					</div>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">
					{{ $t('sessionParking.modal.endSession.modalConfirm') }}
				</button>
			</template>
			<template v-slot:cancelButton>
				<a class="cancel" @click="closeModal">{{
					$t('sessionParking.modal.endSession.modalClose')
				}}</a>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PreviousButton from '@/components/PreviousButton.vue'
import activesession from '../../components/session/activesession.vue'
import SessionModal from '@/components/SessionModal.vue'
import SessionRequired from '@/mixins/session-required'
import Loading from '@/components/Loading.vue'
import { BOURSE } from '../../store/modules/session'

export default {
	name: 'HowSessionStart',
	components: {
		activesession,
		PreviousButton,
		SessionModal,
		Loading,
	},
	mixins: [SessionRequired],
	data() {
		return {
			showModal: false,
			modalTitle: '',
			modalDescription: '',
			modalAlertText: '',
			previousRoute: null,
			sessionActive: [],
			totalAmountPaid: '0',
		}
	},
	computed: {
		modalContent() {
			if (this.previousRoute && this.previousRoute.matched.length > 0) {
				const previousRoutePath = this.previousRoute.matched[0].path
				if (previousRoutePath === '/entering-qr' || previousRoutePath === '/main-door-qr') {
					return {
						title: this.$t('sessionParking.modal.expireSession.modalTitle'),
						description: this.$t('sessionParking.modal.expireSession.modalDescription'),
						alertText: this.$t('sessionParking.modal.expireSession.modalAlertText'),
					}
				} else {
					return {
						title: this.$t('sessionParking.modal.endSession.modalTitle'),
						description: this.$t('sessionParking.modal.endSession.modalDescription'),
						alertText: this.$t('sessionParking.modal.endSession.modalAlertText'),
					}
				}
			} else {
				return {
					title: this.$t('sessionParking.modal.endSession.modalTitle'),
					description: this.$t('sessionParking.modal.endSession.modalDescription'),
					alertText: this.$t('sessionParking.modal.endSession.modalAlertText'),
				}
			}
		},
		...mapState({
			session: (state) =>
				state.session
					? state.session.sessionStop.length > 0
						? state.session.sessionStop
						: state.session.sessionActive
					: null,
			isLoading: (state) => state.session.isLoading,
			errorMessage: (state) => state.session.error,
			serverTime: (state) => state.serverTime,
		}),
	},
	watch: {
		session: {
			immediate: true, // Trigger the watcher immediately after component is created
			handler(newVal) {
				if (!newVal) return [] // Return if sessionActive is null or undefined
				this.sessionActive = newVal
				if (newVal) {
					this.calculateParkingFee()
				}
			},
		},
	},
	methods: {
		...mapActions('session', ['generateQrCode', 'endSession']),
		...mapActions('alert', ['error']),
		calculateParkingFee() {
			if (
				this.session &&
				this.session.length > 0 &&
				this.session[0].sessionParking &&
				this.session[0].sessionParking.parkingSessionConfigs
			) {
				if (this.session[0].endTimeInMs) {
					const freeHours = this.session[0].sessionParking.parkingSessionConfigs[0].subscriptionFreeHours
					const perHourCost = this.session[0].sessionParking.parkingSessionConfigs[0].perHourCost

					const currentTime = this.serverTime
					const startTime = this.session[0].endTimeInMs
					const elapsedMs = currentTime - startTime
					// Calculate the total hours elapsed
					const elapsedHours = elapsedMs / (1000 * 60 * 60)
					// Calculate the rounded total hours elapsed (rounding up to the nearest whole number)
					const roundedElapsedHours = Math.ceil(elapsedHours)
					// Calculate hours that should be paid for after excluding free hours
					const payableHours = Math.max(0, roundedElapsedHours - freeHours)
					// Calculate the number of 24-hour periods within the payable hours
					const fullDays = Math.ceil(payableHours / 24)
					// const remainingHours = payableHours % 24
					// Calculate the amount for full 24-hour periods
					const fullDaysCharge = fullDays * perHourCost
					// Calculate the amount for remaining hours after the last full 24-hour period
					// const remainingHoursCharge = remainingHours > 0 ? perHourCost : 0
					// Total amount to be paid
					const totalAmountPaid = parseFloat(fullDaysCharge)
					// + parseFloat(remainingHoursCharge)
					// Multiply by the number of bikes and round to 2 decimal places
					this.totalAmountPaid = (totalAmountPaid * this.session[0].bikes).toFixed(2)
				} else {
					const freeHours = this.session[0].sessionParking.parkingSessionConfigs[0].subscriptionFreeHours
					const perHourCost = this.session[0].sessionParking.parkingSessionConfigs[0].perHourCost

					const currentTime = this.serverTime
					const startTime = this.session[0].startTimeInMs
					const elapsedMs = currentTime - startTime

					// Calculate the total hours elapsed
					const elapsedHours = elapsedMs / (1000 * 60 * 60)

					// Calculate the rounded total hours elapsed (rounding up to the nearest whole number)
					const roundedElapsedHours = Math.ceil(elapsedHours)
					// Calculate hours that should be paid for after excluding free hours
					const payableHours = Math.max(0, roundedElapsedHours - freeHours)
					// Calculate the number of 24-hour periods within the payable hours
					const fullDays = Math.ceil(payableHours / 24)
					// const remainingHours = payableHours % 24
					// Calculate the amount for full 24-hour periods
					const fullDaysCharge = fullDays * perHourCost
					// Calculate the amount for remaining hours after the last full 24-hour period
					// const remainingHoursCharge = remainingHours > 0 ? perHourCost : 0
					// Total amount to be paid
					const totalAmountPaid = parseFloat(fullDaysCharge)
					// + parseFloat(remainingHoursCharge)
					// Multiply by the number of bikes and round to 2 decimal places
					this.totalAmountPaid = (totalAmountPaid * this.session[0].bikes).toFixed(2)
				}
			}
		},
		openModal() {
			// Show the default modal
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async confirmAction() {
			const sessionQrExpire = localStorage.getItem('qrTimeout')
			if (sessionQrExpire) {
				// check if sessionQrExpire is found
				localStorage.removeItem('qrTimeout')
			}

			// Get sessionId from local storage
			const sessionId = localStorage.getItem('sessionId')

			if (!sessionId) {
				console.error('Session ID not found in local storage')
				return
			}

			// Convert sessionId to an integer
			const numericSessionId = parseInt(sessionId, 10)

			// Check if the conversion is successful
			if (isNaN(numericSessionId)) {
				console.error('Invalid session ID:', sessionId)
				return
			}

			// Call the action with the required sessionProps
			const currentTime = new Date()
			const hours = currentTime.getHours()
			if (
				this.session[0].parkingId == BOURSE.ID &&
				hours >= BOURSE.NIGHT.START &&
				hours <= BOURSE.NIGHT.END
			) {
				// Perform action when confirmed
				try {
					// first need to confirm the payment if it is paid or failed
					const res = await this.endSession(parseInt(sessionId))
					console.log('paymentStatus', res.status)
					if (res.status === 'failed') {
						this.error(this.errorMessage)
						this.error(`Payment failed: ${res.description}. Please try another card`)
						setTimeout(() => {
							this.$router.push({
								path: '/how-it-pay',
								query: {
									parkingId: this.sessionActive[0].parkingId,
									session: true,
									payment:'failed'
								},
							})
						}, 3000)

					} else if (res.status === 'paid') {
						const sessionProps = { sessionId: numericSessionId, codeType: 'MDPENT' }
						// Call the action with the provided sessionProps
						const response = await this.generateQrCode(sessionProps)

						// const newSessionProps = {
						// 	sessionId: numericSessionId,
						// 	codeType: 'ENT', // Provide the actual code type
						// }
						// await this.generateQrCode(newSessionProps)
						// Handle the response data here
						this.$router.push('/main-door-qr') // Update route based on your requirements
					}
					return
				} catch (error) {
					console.error('Error stopping session:', error)
				}
			} else {
				const sessionProps = {
					sessionId: numericSessionId,
					codeType: 'ENT', // Provide the actual code type
				}

				// Perform action when confirmed
				try {
					// first need to confirm the payment if it is paid or failed
					const res = await this.endSession(parseInt(sessionId))
					if (res.status === 'failed') {
						this.error(this.errorMessage)
						this.error(`Payment failed: ${res.description}. Please try another card`)
						setTimeout(() => {
							this.$router.push({
								path: '/how-it-pay',
								query: {
									parkingId: this.sessionActive[0].parkingId,
									session: true,
									payment:'failed'
								},
							})
						}, 3000)
					} else if (res.status === 'paid') {
						// Call the action with the provided sessionProps
						const response = await this.generateQrCode(sessionProps)
						// Handle the response data here
						this.$router.push('/entering-qr') // Update route based on your requirements
					}
				} catch (error) {
					console.error('Error stopping session:', error)
				}
			}

			this.closeModal()
		},
	},
	mounted() {
		this.intervalId = setInterval(() => {
			this.calculateParkingFee()
			this.serverTime = this.serverTime + 300000
		}, 300000)
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
	},
	beforeRouteEnter(to, from, next) {
		// Pass the previous route to the component
		next((vm) => {
			vm.previousRoute = from
			// Open the modal if returning from 'entering-qr'
			if (from.path === '/entering-qr' || from.path === '/main-door-qr') {
				vm.showModal = true
			}
		})
	},
	beforeRouteUpdate(to, from, next) {
		this.previousRoute = from

		// Open the modal if returning from 'entering-qr'
		if (from.path === '/entering-qr' || from.path === '/main-door-qr') {
			this.showModal = true
		}

		next()
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
.mollie {
	width: 30%;
	/* position: relative; */
	margin: 0px auto;
}
.bg {
	padding-bottom: 62px;
}

@media screen and (max-width: 768px) {
	.mollie {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	.roww {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}
}
</style>
