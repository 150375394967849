import APIClient from '../api/apiClient'

const InvoiceItemsService = {
	// Service to get all invoice items
	getInvoiceItems: (params) => {
		return APIClient.get(`/invoice-items/all-invoice-items`, { params })
	},

	// Service to get invoice item by ID
	getInvoiceItemById: (itemid) => {
		return APIClient.get(`/invoice-items/invoice-item-operations/${itemid}`)
	}
}

export default InvoiceItemsService
