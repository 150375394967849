<template>
	<div class="bg-blue">
		<Loading v-if="!session || isLoading" />
		<div v-else="session && !isLoading" class="start-session-qr">
			<GenerateQr
				:type="localizedData.scanType"
				:title="localizedData.title"
				:description="localizedData.description"
				:sessionId="session ? session.sessionId : '0'"
				:qrCodeText="session ? session.sessionQRCodes[0].qrCode : 'scan to encode'"
				:numOfScan="session ? session.sessionQRCodes[0].used : 0"
				:totalScan="session ? session.sessionQRCodes[0].usage : 0"
				:parkingId="session ? (session.sessionQRCodes.length > 0 ? session.parkingId : null) : null"
				:message="localizedData.message"
			/>
		</div>
	</div>
</template>

<script>
import GenerateQr from '@/components/session/GenerateQr.vue'
import SessionRequired from '@/mixins/session-required'
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import io from 'socket.io-client'
import { config } from '@/config'

export default {
	name: 'SessionStartQr',
	components: {
		GenerateQr,
		Loading,
	},
	mixins: [SessionRequired],
	data() {
		return {
			qrScannedStatus: null,
			isLoading: false,
		}
	},
	computed: {
		localizedData() {
			return {
				scanType: 'session-start-qr',
				title: this.$t('sessionParking.qrCode1.title'),
				description: this.$t('sessionParking.qrCode1.description'),
				message: this.$t('sessionParking.qrCode1.message'),
			}
		},
		...mapState({
			session: (state) =>
				state.session.sessionStart.length > 0 ? state.session.sessionStart[0] : null,
		}),
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),
	},
	methods: {
		...mapActions('session', ['getSessionById']),
		...mapActions('alert', ['error']),
		...mapActions('profile', ['getCurrentUser']),
		async loadSessionPeriodically() {
			const sessionId = localStorage.getItem('sessionId')

			if (sessionId) {
				const numericSessionId = parseInt(sessionId, 10)

				if (!isNaN(numericSessionId)) {
					const sessionProps = {
						sessionId: numericSessionId,
					}
					try {
						// Dispatch the action properly
						await this.getSessionById(sessionProps)
					} catch (error) {
						console.error('Error fetching session:', error)
					}
				}
			}
		},
	},
	async created() {
		// Load the current user first
		// await this.getCurrentUser();
		if (!this.currentUser?.id) {
			await this.getCurrentUser()
		}
		// Assuming the user ID is stored in the Vuex state after loading the current user
		const userId = this.currentUser.id
		// Connect to the Socket.IO server
		this.socket = io(config.socketUrl, { transports: ['websocket'], query: { userId } }) // Replace with your server URL
		// Handle event from the server for Subscription first charge
		this.socket.on('QrScanned', (response) => {
			this.qrScannedStatus = response
			if (this.qrScannedStatus) {
				if (this.qrScannedStatus.success === true) {
					const rfId = this.qrScannedStatus.rfid
					if (rfId === this.session.sessionQRCodes[0].qrCode) {
						this.isLoading = true
						setTimeout(() => {
							this.isLoading = false
						}, 5000)
						this.loadSessionPeriodically()
					}
				} else {
					this.error(this.qrScannedStatus.message)
				}
			}
		})

		// Example: handling connection error
		this.socket.on('connect_error', (error) => {
			console.error('Connection error:', error)
		})
	},
	beforeDestroy() {
		this.socket.off('QrScanned')
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
</style>
