import APIClient from '../api/apiClient'

const GeneralService = {
	/**
	 * @description will get current server time
	*/
	getServerTime: () => {
		return APIClient.get(`/server-time`).then(response => response?.data)
	},
	/**
	 * @description service for log error
	 */
	logError: (error) => {
		return APIClient.post(`/log-error`, error)
	}

}

export default GeneralService
