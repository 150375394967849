import APIClient from '../api/apiClient'

const HistoryLogsService = {
	// Service to get all history logs
	getHistoryLogs: (params) => {
		return APIClient.get(`/history-logs/all-logs`, { params })
	},

	// Service to get last 6 months history logs
	getHistoryLogsLast6Months: (params) => {
		return APIClient.get(`/history-logs/six-month-logs`, { params })
	}
}

export default HistoryLogsService
