import gql from 'graphql-tag'
import graphqlClient from '@/api/db'
import {
	omit,
	map,
	findKey,
	filter,
	reject,
	includes,
	find,
	flatten,
	sumBy,
	reverse,
} from 'lodash/fp'
import { date } from '@/helpers/'
import {
	REQUEST_STATES,
	PROPOSAL_STATES,
	USER_STATES,
	SUBSCRIPTION_STATES,
	DEPOSIT_STATES,
	INVOICE_ITEM_STATES,
	DEFAULT_QUERY_LIMIT,
	PARKING_QUERY_LIMIT,
	REQUEST_QUERY_LIMIT,
	ignoredParkingTypes,
	invoiceItemTypes,
	keyTypes,
} from '@/config'
import i18n from '@/i18nVeeValidate'
import HistoryLogsService from '../../../services/historyLogs'
import InvoiceItemsService from '../../../services/invoiceItems'
import ProposalsService from '../../../services/proposals'
import SubscriptionsService from '../../../services/subscriptions'
import ParkingsService from '../../../services/parkings'
import RequestsService from '../../../services/requests'
import UserService from '../../../services/userService'

// import { logger } from '@/logger'

// Initial state
const initialState = () => ({
	data: [],
})

export const parkingExportProps = `
	id
	name
	code
	parkingTypeName
	capacityClassic
	capacityCargo
	spotsAvailableClassic
	spotsAvailableCargo
	active
	latitude
	longitude
	numberOfRequestsInScope
	numberOfLegacyRequests
	legacyAddress {
		nl
		fr
	}
	keyType
	numberOfActiveDeposits
`

const requestExportProps = `
	id
	comment
	requestedAt
	type
	isCargo
	status
	addressOrUserHome {
		id
		streetName
		houseNumber
		postalCode
		city
		addressType
	}
	user {
		id
		firstName
		lastName
		email
	}
`

const userExportProps = `
	id
	firstName
	lastName
	email
	phoneNumber
	status
	language
	newsletter
	createdAt
	subscriptions {
		id
		parkingCode
		status
		end
	}
	addresses {
		id
		streetName
		houseNumber
		postalCode
		city
		addressType
	}
	parent {
		id
		email
	}
`

export const subscriptionExportProps = `
	id
	start
	end
	remainingMonths
	createdAt
	status
	stripeId
	spotNumber
	user {
		id
		email
		homeAddress {
			postalCode
		}
	}
	parkingCode
	deposits {
		id
		status
		isPaid
		price
		reimbursedAt
		stripeId
		refunds {
			id
		}
	}
	proposal {
		request {
			requestedAt
		}
	}
	externalDeposit
	isCommune
	paymentMethod
`

export const proposalExportProps = `
	id
	createdAt
	lastStatusChangedAt
	status
	validUntil
	creator {
		id
		email
	}
	parking {
		id
		code
	}
	request {
		id
		isCargo
		user {
			id
			email
		}
	}
`

export const invoiceItemExportProps = `
	id
	createdAt
	status
	type
	amount
	paid
	paidAt
	subscription {
		id
		user {
			email
			parentId
			parent {
				id
				email
			}
		}
	}
	refunds {
		amount
	}
`
export const auditTrailExportProps = `
	id
	createdAt
	action
	user {
		email
	}
`

export const parkingFields = {
	id: 'id',
	name: 'name',
	code: 'code',
	parkingTypeName: 'parkingTypeName',
	capacityClassic: 'capacityClassic',
	capacityCargo: 'capacityCargo',
	spotsAvailableClassic: 'spotsAvailableClassic',
	spotsAvailableCargo: 'spotsAvailableCargo',
	active: 'active',
	latitude: 'latitude',
	longitude: 'longitude',
	numberOfRequestsInScope: 'numberOfRequestsInScope',
	numberOfLegacyRequests: 'numberOfLegacyRequests',
	numberOfActiveDeposits: 'numberOfActiveDeposits',
	addressNL: 'legacyAddress.nl',
	addressFR: 'legacyAddress.fr',
	keyType: {
		field: 'keyType',
		callback: (value) => {
			return findKey((s) => s === value, keyTypes)
		},
	},
}

export const parkingHeatmapFields = {
	id: 'id',
	name: 'name',
	code: 'code',
	parkingTypeName: 'parkingTypeName',
	capacityClassic: 'capacityClassic',
	capacityCargo: 'capacityCargo',
	active: 'active',
	latitude: 'latitude',
	longitude: 'longitude',
	numberOfRequestsInScope: 'numberOfRequestsInScope',
	addressFR: 'legacyAddress.fr',
	addressNL: 'legacyAddress.nl',
}

export const invoiceItemFields = {
	type: {
		field: 'type',
		callback: (value) => {
			return findKey((s) => s === value, invoiceItemTypes)
		},
	},
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, INVOICE_ITEM_STATES)
		},
	},
	amount: 'invoice.amount',
	paid: 'invoice.paid',
	paidAt: {
		field: 'invoice.paidAt',
		callback: (value) => {
			return value ? date(value) : ''
		},
	},
	user: {
		field: 'subscription.user',
		callback: (value) => {
			if (value.parentId) {
				return value.parent.email
			} else {
				return value.email
			}
		},
	},
	refunds: {
		field: 'refunds',
		callback: (refunds) => {
			return sumBy((r) => r.amount, refunds)
		},
	},
	createdAt: {
		field: 'createdAt',
		callback: (value) => {
			return date(value)
		},
	},
}

export const auditTrailFields = {
	user: {
		field: 'user.email',
	},
	action: 'action',
	createdAt: {
		field: 'createdAt',
		callback: (value) => {
			return date(value)
		},
	},
}

export const proposalFields = {
	id: 'id',
	parking: 'parking.code',
	user: 'request.user.email',
	creator: {
		field: 'creator.email',
		callback: (value) => {
			return value || 'auto'
		},
	},
	spotType: {
		field: 'request.isCargo',
		callback: (value) => {
			return value ? 'Cargo' : 'Classic'
		},
	},
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, PROPOSAL_STATES)
		},
	},
	createdAt: {
		field: 'createdAt',
		callback: (value) => {
			return date(value)
		},
	},
	'accepted/rejected at': {
		field: 'lastStatusChangedAt',
		callback: (value) => {
			return date(value)
		},
	},
	validUntil: {
		field: 'validUntil',
		callback: (value) => {
			return date(value)
		},
	},
}

export const subscriptionFields = {
	'Sub id': 'id',
	email: 'user.email',
	'User id': 'user.id',
	userPostalCode: {
		field: 'user.homeAddress.postalCode',
		callback: (value) => {
			return value || '-'
		},
	},
	parking: {
		field: 'parkingCode',
		callback: (value) => {
			return value || 'Big Parking'
		},
	},
	spotNumber: 'spotNumber',
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, SUBSCRIPTION_STATES)
		},
	},
	paymentMethod: 'paymentMethod',
	'start date': {
		field: 'start',
		callback: (value) => {
			return date(value)
		},
	},
	'end date': {
		field: 'end',
		callback: (value) => {
			return date(value)
		},
	},
	'remaining months': {
		field: 'remainingMonths',
	},
	'unused months': {
		field: 'unusedMonths',
	},
	'created at': {
		field: 'createdAt',
		callback: (value) => {
			return date(value)
		},
	},
	'requested at': {
		field: 'proposal',
		callback: (proposal) => {
			return proposal ? date(proposal.request.requestedAt) : ''
		},
	},
	'sub stripeId': 'stripeId',
	'commune subscription': {
		field: 'isCommune',
	},
	'commune deposit': {
		field: 'externalDeposit',
	},
	depositStatus: {
		field: 'deposits',
		callback: (value) => {
			const lastDeposit = reverse(value)[0]
			let state = null

			if (lastDeposit) {
				state = findKey((s) => s === lastDeposit.status, DEPOSIT_STATES)

				// state is 'unpaid' if it is 'active' but isPaid is false
				if (state === 'active' && !lastDeposit.isPaid) {
					state = 'paymentPending'
				}
			}

			return state ? i18n.t(`subscription.depositStatuses.${state}`) : ''
		},
	},

	depositPrice: {
		field: 'deposits',
		callback: (value) => {
			const lastDeposit = reverse(value)[0]

			return lastDeposit && lastDeposit.price ? lastDeposit.price : ''
		},
	},
	'deposit reimbursed at': {
		field: 'deposits',
		callback: (value) => {
			const lastDeposit = reverse(value)[0]

			return lastDeposit && lastDeposit.reimbursedAt ? date(lastDeposit.reimbursedAt) : ''
		},
	},

	'deposit stripeId': {
		field: 'deposits',
		callback: (value) => {
			const lastDeposit = reverse(value)[0]

			return lastDeposit && lastDeposit.stripeId ? lastDeposit.stripeId : ''
		},
	},
	stripeReimbursed: {
		field: 'deposits',
		callback: (value) => {
			const lastDeposit = reverse(value)[0]

			if (lastDeposit) {
				const stripeRefunds = filter((r) => r.stripeId, lastDeposit.refunds)

				return stripeRefunds.length > 0 ? 'Y' : 'N'
			}

			return 'N'
		},
	},
	'multiple deposits': {
		field: 'deposits',
		callback: (value) => {
			return value.length > 1 ? 'Y' : 'N'
		},
	},
}

export const requestFields = {
	id: 'id',
	comment: 'comment',
	type: 'type',
	address: {
		field: 'address',
		callback: (address) =>
			address
				? `${address.streetName}, ${address.houseNumber} ${address.postalCode} ${address.city}`
				: 'home address N/A',
	},
	postalCode: 'address.postalCode',
	addressType: 'address.addressType',
	email: 'user.email',
	name: {
		field: 'user',
		callback: (value) => {
			return `${value.lastName} ${value.firstName}`
		},
	},
	requestDate: {
		field: 'requestedAt',
		callback: (value) => {
			return date(value)
		},
	},
	spotType: {
		field: 'isCargo',
		callback: (value) => {
			return value ? 'Cargo' : 'Classic'
		},
	},
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, REQUEST_STATES)
		},
	},
}

export const requestHeatmapFields = {
	id: 'id',
	address: {
		field: 'address',
		callback: (address) =>
			address
				? `${address.streetName}, ${address.houseNumber} ${address.postalCode} ${address.city}`
				: 'home address N/A',
	},
	postalCode: 'address.postalCode',
	addressType: 'address.addressType',
	requestDate: {
		field: 'requestedAt',
		callback: (value) => {
			return date(value)
		},
	},
	spotType: {
		field: 'isCargo',
		callback: (value) => {
			return value ? 'Cargo' : 'Classic'
		},
	},
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, REQUEST_STATES)
		},
	},
}
export const statusToString = (status) => {
	return parseInt(status.toString().charAt(0))
}
export function processUserPayload(users) {
	// Recursive function to process users and their children
	function processUser(user) {
	  let proposals = [];
  
	  // Process user's requests and proposals
	 user.requests ? user.requests.forEach(request => {
		if (request.proposals){proposals = [...proposals, ...request.proposals];} 
		}) : proposals = [];
  
	  user.proposals = proposals;
  
	  // Process user's subscriptions
	  user.subscriptions ? user.subscriptions.forEach(sub => {
		sub.status = statusToString(sub.status);
		sub.deposits && sub.deposits.forEach(deposit => {
		  deposit.price = parseInt(deposit.price);
		}							
		);
		// each invoiceItems has a list of refunds that amount needs to be int
		sub.invoiceItems && sub.invoiceItems.forEach(invoiceItem => {
			invoiceItem.amount = parseInt(invoiceItem.amount);
			invoiceItem.refunds && invoiceItem.refunds.forEach(refund => {
			refund.amount = parseInt(refund.amount);
			}); 
		});
		
		// each subscription has a list of refunds that amount needs to be int
		sub.refunds && sub.refunds.forEach(refund => {
		  refund.amount = parseInt(refund.amount);
		}
		)
	  }) : [];
  
	  // Process user's children recursively
	  if (user.children && Array.isArray(user.children)) {
		user.children.forEach(child => {
		  processUser(child);
		});
	  }
	}
	if (!Array.isArray(users)) {
		processUser(users);
		console.log("🚀 ~ processUserPayload ~ users:", users)
	  return users;
	}else{
	users.forEach(user => {
		processUser(user);
	});
	console.log("🚀 ~ processUserPayload ~ users:", users)
	return users;
	}	
  }

export const userFields = {
	id: 'id',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	status: {
		field: 'status',
		callback: (value) => {
			return findKey((s) => s === value, USER_STATES)
		},
	},
	'registered on': {
		field: 'createdAt',
		callback: (val) => (val ? date(val) : null),
	},
	activeSubscriptions: {
		field: 'subscriptions',
		callback: (values) => {
			const sub = filter((s) => s.status > 1 && s.status < 4, values)

			return sub ? sub.length : 0
		},
	},

	'address HOME': {
		field: 'addresses',
		callback: (values) => {
			const address = find({ addressType: 'home' }, values)

			return address
				? `${address.streetName}, ${address.houseNumber} ${address.postalCode} ${address.city}`
				: ''
		},
	},
	'address POI 1': {
		field: 'addresses',
		callback: (values) => {
			const addresses = filter({ addressType: 'poi' }, values)
			const address = addresses[0]

			return address
				? `${address.streetName}, ${address.houseNumber} ${address.postalCode} ${address.city}`
				: ''
		},
	},
	'address POI 2': {
		field: 'addresses',
		callback: (values) => {
			const addresses = filter({ addressType: 'poi' }, values)
			const address = addresses[1]

			return address
				? `${address.streetName}, ${address.houseNumber} ${address.postalCode} ${address.city}`
				: ''
		},
	},
	parent: 'parent.email',
	language: 'language',
	newsletter: 'newsletter',
}

// const fetchInBlocks = async ({ limit, accessor, query, variables }) => {
// 	let offset = 0
// 	let batch = {}
// 	const results = []

// 	do {
// 		batch = await graphqlClient.query({
// 			query,
// 			variables: Object.assign({}, variables, {
// 				limit,
// 				offset,
// 			}),
// 		})
// 		results.push(batch.data[accessor])
// 		offset += limit
// 	} while (batch.data[accessor].length === limit)

// 	return results
// }

const fetchInBlocksForRestApi = async ({ limit, accessor, query }) => {
	let offset = 0
	let sortkey = 'id'
	let sortorder = 'asc'
	let batch = {}
	const results = []

	do {
		batch = await query({
			limit,
			offset,
			sortkey,
			sortorder
		})
		results.push(batch.data[accessor])
		offset += limit
	} while (batch.data[accessor].length === limit)

	return results
}

const state = initialState()

const getters = {}

const actions = {
	async exportInvoiceItems({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const response = await fetchInBlocks({
			// 	limit: DEFAULT_QUERY_LIMIT,
			// 	accessor: 'allInvoiceItems',
			// 	query: gql`
			// 		query allInvoiceItems($limit: Int, $offset: Int) {
			// 			allInvoiceItems(limit: $limit, offset: $offset) {
			// 					${invoiceItemExportProps}
			// 				}
			// 		}
			// 	`,
			// })

			const response = await fetchInBlocksForRestApi({
				limit: DEFAULT_QUERY_LIMIT,
				accessor: 'requests',
				query: InvoiceItemsService.getInvoiceItems,
			})	

			commit('setExport', flatten(response))
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},
	async exportAuditTrails({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const response = await fetchInBlocks({
			// 	limit: DEFAULT_QUERY_LIMIT,
			// 	accessor: 'allHistoryLogs',
			// 	query: gql`
			// 		query allHistoryLogs($limit: Int, $offset: Int) {
			// 			allHistoryLogs(limit: $limit, offset: $offset) {
			// 					${auditTrailExportProps}
			// 				}
			// 		}
			// 	`,
			// })
			const response = await fetchInBlocksForRestApi({
				limit: DEFAULT_QUERY_LIMIT,
				accessor: 'historyLogs',
				query: HistoryLogsService.getHistoryLogsLast6Months,
			})
			commit('setExport', flatten(response))
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},
	async exportProposals({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const proposals = await fetchInBlocks({
			// 	limit: DEFAULT_QUERY_LIMIT,
			// 	accessor: 'allProposals',
			// 	query: gql`
			// 		query allProposals($limit: Int, $offset: Int) {
			// 			allProposals(limit: $limit, offset: $offset) {
			// 					${proposalExportProps}
			// 				}
			// 		}
			// 	`,
			// })
			const proposals = await fetchInBlocksForRestApi({
				limit: DEFAULT_QUERY_LIMIT,
				accessor: 'allProposals',
				query: ProposalsService.getProposals,
			})
			commit('setExport', flatten(proposals))
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async exportSubscriptions({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const subscriptions = await fetchInBlocks({
			// 	limit: DEFAULT_QUERY_LIMIT,
			// 	accessor: 'allSubscriptions',
			// 	query: gql`
			// 		query allSubscriptions($limit: Int, $offset: Int) {
			// 			allSubscriptions(limit: $limit, offset: $offset) {
			// 					${subscriptionExportProps}
			// 				}
			// 		}
			// 	`,
			// })
			const subscriptions = await fetchInBlocksForRestApi({
				limit: DEFAULT_QUERY_LIMIT,
				accessor: 'allSubscriptions',
				query: SubscriptionsService.getSubscriptions,
			})
			const mappedSubscriptions = flatten(subscriptions).reduce((l, s) => {
			if (s.status > SUBSCRIPTION_STATES.new) {
					const isActive = s.status < SUBSCRIPTION_STATES.cancelled

					l.push(
						Object.assign({}, s, {
							unusedMonths: isActive ? '-' : s.remainingMonths,
							remainingMonths: isActive ? s.remainingMonths : '-',
						})
					)
				}

				return l
			}, [])

			commit('setExport', mappedSubscriptions)
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},
	async exportParkings({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const response = await fetchInBlocks({
			// 	limit: PARKING_QUERY_LIMIT,
			// 	accessor: 'allParkings',
			// 	query: gql`
			// 		query allParkings($limit: Int, $offset: Int) {
			// 			allParkings(limit: $limit, offset: $offset) {
			// 					${parkingExportProps}
			// 				}
			// 		}
			// 	`,
			// })
			const response = await fetchInBlocksForRestApi({
				limit: PARKING_QUERY_LIMIT,
				accessor: 'parkings',
				query: ParkingsService.getAllParkings,
			})
			const allParkings = flatten(response)

			commit(
				'setExport',
				reject((p) => includes(p.parkingTypeName, ignoredParkingTypes), allParkings)
			)
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async exportRequests({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const requests = await fetchInBlocks({
			// 	limit: REQUEST_QUERY_LIMIT,
			// 	accessor: 'allRequests',
			// 	query: gql`
			// 		query allRequests($limit: Int, $offset: Int) {
			// 			allRequests(limit: $limit, offset: $offset) {
			// 					${requestExportProps}
			// 				}
			// 		}
			// 	`,
			// })
			const requests = await fetchInBlocksForRestApi({
				limit: REQUEST_QUERY_LIMIT,
				accessor: 'requests',
				query:  RequestsService.getRequests,
			})

			// had to split request into separate calls due to query timeouts

			const allRequests = flatten(requests)

			// const enrichedRequests = map(
			// 	(req) => Object.assign({ address: req.addressOrUserHome }, omit('addressOrUserHome', req)),
			// 	allRequests
			// )

			commit('setExport', allRequests)
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async exportUsers({ commit, dispatch }) {
		commit('setLoading', true)

		try {
			// const users = await fetchInBlocks({
			// 	query: gql`
			// 		query users($limit: Int, $offset: Int) {
			// 			users(limit: $limit, offset: $offset) {
			// 					${userExportProps}
			// 				}
			// 		}
			// 	`,
			// 	limit: DEFAULT_QUERY_LIMIT,
			// 	accessor: 'users',
			// })
			const users = await fetchInBlocksForRestApi({
				limit: DEFAULT_QUERY_LIMIT,
				accessor: 'users',
				query: UserService.getUsers,
			})

			// commit('setExport', concat(responseA.data.users, responseB.data.users))
			commit('setExport', flatten(users))
		} catch (error) {
			dispatch('alert/error', 'Something went wrong', { root: true })
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	setExport(state, data) {
		state.data = map((d) => omit(['__typename'], d), data)
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
