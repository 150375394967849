import graphqlClient from '../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'
import router from '@/router'
import { config, TEMPORARY_ACCESS_STATUSES } from '@/config'
import { findKey } from 'lodash/fp'
import { logger } from '@/logger'
import TemporaryAccessService from '../../services/temporaryAccess'

// Initial state
const initialState = () => ({
	loading: false,
	refreshing: false,
	parking: {
		id: null,
		name: null,
		code: null,
	},
	proposal: null,
	currentTemporaryAccess: {
		id: null,
	},
	userAlreadyHadATa: false,
})

const state = initialState()

export const parkingProps = `
		id
		name
		code
		legacyAddress {
			nl
			fr
			en
		}
	`
export const temporaryAccessProps = `
		id
		parkingId
		startKey
		endKey
		price
		startCodeGeneratedAt
		endCodeGeneratedAt
		startedAt
		endedAt
		potentialEndedAt
		status
		active
		paymentUrl
		canRetry
		retryPendingSince
		lastScannedAt
		payByCreditCard
	`
export const temporaryAccessPropsWithParking = `
                ${temporaryAccessProps}
		parking {
		  ${parkingProps}
		}
	`
export const parkingOrExistingTemporaryAccessProps = `
		parking {
		  ${parkingProps}
		}
		temporaryAccess {
		    ${temporaryAccessPropsWithParking}
		}
		userAlreadyHadATa
	`

export const temporaryAccessStatusName = ({ status }) => {
	// active: new, pending, active or expired
	return findKey((v) => v === parseInt(status, 10), TEMPORARY_ACCESS_STATUSES)
}

// Getters
const getters = {
	getTemporaryAccessRediretionUrl: () => {
		return config.temporaryAccessRedirectUrl
	},
}

// Actions

const actions = {
	// eslint-disable-next-line max-statements
	setCurrentTemporaryAccess({ commit, dispatch }, ta) {
		commit('setCurrentTemporaryAccess', ta)

		if (ta.active) {
			dispatch(
				'profile/setTemporaryAccess',
				Object.assign({}, ta, { parking: ta.parking || state.parking }),
				{ root: true }
			)
		}
	},
	async loadParkingOrExistingTa({ commit, dispatch }, parkingCode) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query GetParkingOrExistingTemporaryAccess($parkingCode: String!) {
			// 			getParkingOrExistingTemporaryAccess(parkingCode: $parkingCode) {
			// 				${parkingOrExistingTemporaryAccessProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		parkingCode,
			// 	},
			// 	fetchPolicy: 'network-only',
			// })
			const response = await TemporaryAccessService.getParkingOrExistingTemporaryAccess(parkingCode)

			const parkingAndTa = response.data
			const parking = parkingAndTa.parking
			const userAlreadyHadATa = parkingAndTa.userAlreadyHadATa

			if (parking) {
				commit('setParking', parking)
				commit('setUserAlreadyHadATa', userAlreadyHadATa)

				const temporaryAccess = parkingAndTa.temporaryAccess

				if (temporaryAccess) {
					dispatch('setCurrentTemporaryAccess', temporaryAccess)

					if (parking.id === temporaryAccess.parkingId) {
						router.push({
							name: 'temporaryAccess',
							query: { id: temporaryAccess.id },
						})
					}
				}

				return true
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	async loadTemporaryAccess({ commit, dispatch }, temporaryAccessId) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query GetTemporaryAccess($temporaryAccessId: Int!) {
						getTemporaryAccess(temporaryAccessId: $temporaryAccessId) {
							${temporaryAccessPropsWithParking}
						}
					}
				`,
				variables: {
					temporaryAccessId,
				},
				fetchPolicy: 'network-only',
			})

			const temporaryAccess = response.data.getTemporaryAccess

			if (temporaryAccess) {
				dispatch('setCurrentTemporaryAccess', temporaryAccess)
				commit('setParking', temporaryAccess.parking)

				return true
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	async refreshTemporaryAccess({ commit, dispatch, state }, opts = {}) {
		commit('setRefreshing', true)

		if (opts.setLoading) {
			commit('setLoading', true)
		}

		try {
			const response = await graphqlClient.query({
				query: gql`
					query GetTemporaryAccess($temporaryAccessId: Int!, $simulateScan: Boolean, $simulateExpiration: Boolean, $sendMeTheInvoice: Boolean) {
						getTemporaryAccess(temporaryAccessId: $temporaryAccessId, simulateScan: $simulateScan, simulateExpiration: $simulateExpiration, sendMeTheInvoice: $sendMeTheInvoice) {
							${temporaryAccessProps}
						}
					}
				`,
				variables: {
					temporaryAccessId: state.currentTemporaryAccess.id,
					simulateScan: opts.simulateScan,
					simulateExpiration: opts.simulateExpiration,
					sendMeTheInvoice: opts.sendMeTheInvoice,
				},
				fetchPolicy: 'network-only',
			})

			const temporaryAccess = response.data.getTemporaryAccess

			if (temporaryAccess) {
				dispatch('setCurrentTemporaryAccess', temporaryAccess)

				return true
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setRefreshing', false)

			if (opts.setLoading) {
				commit('setLoading', false)
			}
		}
	},
	async startTemporaryAccess({ commit, dispatch, state }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation StartTemporaryAccess($parkingId: Int!) {
			// 			startTemporaryAccess(parkingId: $parkingId) {
			// 				${temporaryAccessProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		parkingId: state.parking.id,
			// 	},
			// })
			const response = await TemporaryAccessService.startTemporaryAccess({ parkingId: state.parking.id })

			const temporaryAccess = response.data

			dispatch('setCurrentTemporaryAccess', temporaryAccess)

			router.push({
				name: 'temporaryAccess',
				query: { id: temporaryAccess.id },
			})

			return true
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	async retryTemporaryAccess({ commit, dispatch, state }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation RetryTemporaryAccess($id: Int!) {
						retryTemporaryAccess(id: $id) {
							${temporaryAccessProps}
						}
					}
				`,
				variables: {
					id: state.currentTemporaryAccess.id,
				},
			})

			const temporaryAccess = response.data.retryTemporaryAccess

			dispatch('setCurrentTemporaryAccess', temporaryAccess)

			return true
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	async stopTemporaryAccess({ commit, dispatch, state }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation StopTemporaryAccess($id: Int!) {
						stopTemporaryAccess(id: $id) {
							${temporaryAccessProps}
						}
					}
				`,
				variables: {
					id: state.currentTemporaryAccess.id,
				},
			})

			const temporaryAccess = response.data.stopTemporaryAccess

			dispatch('setCurrentTemporaryAccess', temporaryAccess)

			return true
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	goToActiveTemporaryAccess({ commit, state }) {
		router.push({
			name: 'temporaryAccess',
			query: { id: state.currentTemporaryAccess.id },
		})
		commit('setParkingFromTa')
	},
	async updatePaymentMethod({ commit }, url) {
		// How are we supposed to pass parameters without passing a first param? Passing an empty object also doesn't work. And passing commit without commiting anything gives an error. So, let's just commit nothing to get this framework happy.
		commit('setLoading', true)

		const redirectUrl = url

		try {
			const response = await graphqlClient.query({
				query: gql`
					query UpdatePaymentMethod($redirectUrl: String) {
						updatePaymentMethod(redirectUrl: $redirectUrl) {
							url
						}
					}
				`,
				variables: { redirectUrl },
			})

			return response.data.updatePaymentMethod.url
		} catch (e) {
			logger.error('catching error in update payment method', e)

			commit('setLoading', false)

			return false
		}
	},
}

// Mutations
const mutations = {
	setLoading(state, loadingState) {
		state.loading = loadingState
	},
	setRefreshing(state, refreshingState) {
		state.refreshing = refreshingState
	},
	setParkingFromTa(state) {
		state.parking = state.currentTemporaryAccess.parking
	},
	resetState(state) {
		Object.assign(state, initialState())
	},
	setParking(state, parking) {
		state.parking = parking
	},
	setCurrentTemporaryAccess(state, currentTemporaryAccess) {
		state.currentTemporaryAccess = currentTemporaryAccess
	},
	setUserAlreadyHadATa(state, userAlreadyHadATa) {
		state.userAlreadyHadATa = userAlreadyHadATa
	},
	nothing() {},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
