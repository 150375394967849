import APIClient from '../api/apiClient'

const TemporaryAccessService = {
    // Service to get all temporary accesses
    getParkingOrExistingTemporaryAccess: (params) => {
        return APIClient.get(`/temporary-access/parking/${params}`)
    },

    //Service to start a temporary access
    startTemporaryAccess: (payload) => {
        return APIClient.post(`/temporary-access/start`, payload)
    },

}
export default TemporaryAccessService
