import APIClient from '../api/apiClient'

const UserService = {
	// Service to get all users
	getUsers: (params) => {
		return APIClient.get(`/user/all-users`, { params })
	},

	// Service to get user by ID
	getUserById: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}`)
	},

	// Service to get user's children by user ID
	getUserChildrenById: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/children`)
	},

	// Service to get user's parkings by user ID
	getUserParkingById: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/parkings`)
	},

	// Service to get user's subscriptions by user ID
	getUserSubscriptionById: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/subscriptions`)
	},

	// Service to get user's sessions by user ID
	getUserSessionById: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/user-sessions`)
	},

	// Service to send password reset link
	sendPasswordResetLink: (payload) => {
		return APIClient.post(`/user/send-password-reset-link`, payload)
	},

	// Service to update user by ID
	updateUser: (userid, payload) => {
		return APIClient.put(`/user/user-operations/${userid}/update`, payload)
	},
	//Service for update profile
	updateProfile: (userId,payload) => {
		return APIClient.put(`/user/user-operations/${userId}/update-profile`, payload)
	},
	//Service for update user email
	updateUserEmail: (userId,payload) => {
		return APIClient.put(`/user/user-operations/${userId}/update-email`, payload)
	},
	// Service for delete user
	deleteUser: (userid) => {
		return APIClient.delete(`/user/user-operations/${userid}`)
	},
	// Service to add child user
	addChildUser: (payload) => {
		return APIClient.post(`/user/child-operations`, payload)
	},
	// Service to delete child user
	deleteChildUser: (userid) => {
		return APIClient.delete(`/user/child-operations/${userid}`)
	},
	// Service to get payment methods
	getPaymentMethods: () => {
		return APIClient.get(`/user/get-payment-methods`)
	},
	// Service to add payment method
	addPaymentMethod: (token) => {
		return APIClient.post(`/user/add-payment-method`, token)
	},
	//service to set default payment method
	defaultPaymentMethod: (payload) => {
		return APIClient.post(`/user/default-payment-method`, payload)
	},
	//service to get payment details
	getPaymentDetails: (payload) => {
		return APIClient.post(`/user/get-payment-details`, payload)
	},
	//service to update payment method
	updatePaymentMethod: (payload) => {
		return APIClient.post(`/user/update-payment-method`, payload)
	},
	//Service for delete payment method
	deletePaymentMethod: (payload) => {
		return APIClient.post(`/user/delete-payment-method`, payload)
	},
	//Service for upsert address
	upsertAddress: (userId,payload) => {
		return APIClient.post(`/user/address/upsert/${userId}`, payload)
	},
	//Service for delete address
	deleteAddress: (addressId) => {
		return APIClient.delete(`/user/address/${addressId}`)
	},
	// user-operations/{userid}/update-mobib
	updateMobibForUser: (userId,payload) => {
		return APIClient.put(`/user/user-operations/${userId}/update-mobib`, payload)
	},
}

export default UserService
