import APIClient from '../api/apiClient'

const CouponsService = {
	// Service to get all coupons
	getCoupons: (params) => {
		return APIClient.get(`/coupons/all-coupons`, { params })
	},

	// Service to get coupon by ID
	getCouponById: (couponid) => {
		return APIClient.get(`/coupons/coupon-operations/${couponid}`)
	},
	
	// Service to create coupon
	upsertCoupon: (coupon) => {
		return APIClient.post(`/coupons/coupon-operations`, coupon)
	},
}

export default CouponsService
