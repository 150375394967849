<template>
	<Accordion
		:title="$t('sessionParking.sessionActive.title')"
		class="active-session"
		:isOpen="true"
		v-if="userLiveSession"
	>
		<div class="sessions-history">
			<Loading v-if="isLoading" />

			<div class="detail-container">
				<div style="display: flex; align-items: center">
					<img
						src="../../assets//images/session/icon_location_bigparking.svg"
						alt="Add Circle Icon"
						class="icon-svg"
					/>
					<h3 class="h1" style="margin-left: 12px; margin-bottom: 4px">
						<div class="h1" style="text-decoration: none; color: #278ac9">
							{{ userLiveSession.sessionParking && userLiveSession.sessionParking.code }} -
							{{ userLiveSession.sessionParking && userLiveSession.sessionParking.name }}
						</div>
					</h3>
				</div>
				<div class="active-session-address-wrapper">
					<img
						v-if="userLiveSession.sessionParking.legacyAddress.en.includes('(M)')"
						class="active-session-address-wrapper-m"
						src="../../assets/images/session/Métrro.svg"
					/>

					<p class="parking-address">
						{{
							userLiveSession.sessionParking &&
							userLiveSession.sessionParking.legacyAddress[activeLanguage.value].replace('(M)', '')
						}}
					</p>
				</div>

				<table class="details">
					<tr>
						<td class="col2">{{ $t('sessionParking.sessionResult.sessionId') }}</td>
						<td class="spacer"></td>
						<td class="col2">{{ $t('sessionParking.sessionResult.Date') }}</td>
					</tr>
					<tr class="row-values">
						<td class="col">ID {{ userLiveSession.sessionId }}</td>
						<td class="spacer"></td>
						<td class="col">{{ date(userLiveSession.createdAt) }}</td>
					</tr>

					<tr>
						<td class="col2">{{ $t('sessionParking.sessionResult.total') }}</td>
						<td class="spacer"></td>
						<td class="col2">{{ $t('sessionParking.sessionResult.duration') }}</td>
					</tr>
					<tr>
						<td class="col">€{{ totalAmount }}</td>
						<td class="spacer"></td>
						<td class="col">{{ sessionDuration || '-' }}</td>
					</tr>
				</table>
				<div class="button-container">
					<button
						:to="'/how-session-start'"
						class="button red button-session"
						@click="goToSessionDetail"
					>
						{{
							sessionCurrentSatus === 1
								? $t('sessionParking.sessionActive.seeMySession')
								: sessionCurrentSatus === 2
								? $t('sessionParking.sessionActive.confirmBtn')
								: $t('sessionParking.sessionActive.seeMySession')
						}}
					</button>
					<div class="button-session-cancel" @click="openModalCancel" v-if="cancelSessionCta">
						{{ $t('sessionParking.cancelSessionCta') }}
					</div>
				</div>
			</div>
			<SessionModal
				:showModal="modalTerminate.showModal"
				@close="closeTerminateSessionModal"
				@confirm="confirmTerminateSessionAction"
			>
				<template v-slot:title>
					<h1>Terminer la session ?</h1>
				</template>
				<template v-slot:content>
					<p>
						Lorsque vous souhaitez terminer votre session, cliquez sur le bouton « terminer la
						session ». Vous aurez deux CODE QR, un premier pour entrez et un second pour sortir 
					</p>
					<div>
						<div style="display: flex">
							<img
								src="../../assets//images/session//icon_warning_black.svg"
								alt="Add Circle Icon"
								class="icon-svg alert-img"
							/>
							<span class="alert">Le premier CODE QR pour entrer est valable 10 minutes</span>
						</div>
					</div>
				</template>
				<template v-slot:confirmButton>
					<button @click="confirmTerminateSessionAction">Récupérer mon vélo</button>
				</template>
				<template v-slot:cancelButton>
					<a class="cancel" @click="closeTerminateSessionModal">Annuler</a>
				</template>
			</SessionModal>

			<SessionModal
				:showModal="showModalCancel"
				@close="closeModalCancel"
				@confirm="confirmActionCancel"
			>
				<template v-slot:title>
					<h1>{{ $t('sessionParking.modal.cancelSessionModal.modalTitle') }}</h1>
				</template>
				<template v-slot:content>
					<p>
						{{ $t('sessionParking.modal.cancelSessionModal.modalDescription') }}
					</p>
				</template>
				<template v-slot:confirmButton>
					<button @click="confirmActionCancel">
						{{ $t('sessionParking.cancelSessionCta') }}
					</button>
				</template>
			</SessionModal>
		</div>
	</Accordion>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import SessionModal from '@/components/SessionModal.vue'
import Accordion from '@/components/Accordion.vue'
import { date, diffInTime } from '@/helpers'
import SessionService from '../../services/sessions'

export default {
	name: 'SessionActive',
	components: {
		Loading,
		Accordion,
		SessionModal,
	},
	props: {
		subscriptions: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			liveSessionDetailLink: '/',
			modalTerminate: {
				showModal: false,
			},
			sessionDuration: '-',
			sessionTimeInterval: null,
			totalAmount: 0,
			sessionCurrentSatus: 0,
			cancelSessionCta: false,
			showModalCancel: false,
		}
	},
	computed: {
		...mapState('profile', {
			isLoadingCurrentUser: (state) => state.loading,
			currentUser: (state) => state.currentUser,
		}),
		...mapState({
			activeLanguage: (state) => state.language,
			userLiveSession: (state) => state.session.userLiveSession,
			userInAdmin: (state) => state.profile.userInAdmin,
			serverTime: (state) => state.serverTime,
			isLoading: (state) => state.session.isLoading,
		}),
	},
	methods: {
		...mapActions('session', {
			getUserLiveSession: 'getUserLiveSession',
			getLiveSessionDetailLink: 'getLiveSessionDetailLink',
			stopSession: 'generateQrCode',
			getSessionAmountTill: 'getSessionAmountTill',
			getSessionById: 'getSessionById',
			cancelCurrentSession: 'cancelCurrentSession',
			// getSessionStatus: 'getSessionStatus',
		}),
		date,
		diffInTime,
		confirmTerminateSessionModal() {
			this.modalTerminate.showModal = true
		},
		closeTerminateSessionModal() {
			this.modalTerminate.showModal = false
		},
		async confirmTerminateSessionAction() {
			const sessionProps = {
				sessionId: this.userLiveSession.id,
				codeType: 'ENT', // Provide the actual code type
			}

			// Perform action when confirmed
			try {
				// Call the action with the provided sessionProps
				const response = await this.stopSession(sessionProps)
				// Handle the response data here
				this.$router.push('/entering-qr') // Update route based on your requirements
			} catch (error) {
				console.error('Error stopping session:', error)
			}

			this.closeTerminateSessionModal()
		},
		openModalCancel() {
			// Show the default modal
			this.showModalCancel = true
		},
		closeModalCancel() {
			this.showModalCancel = false
		},
		async confirmActionCancel() {
			this.cancelMySession()
			this.closeModalCancel()
		},
		async initiateIntervalBasedUpdates() {
			const startTime = this.userLiveSession.startTimeInMs
			let endTime = this.serverTime
			this.sessionDuration = this.diffInTime(startTime, endTime)
			this.totalAmount = await this.getSessionAmountTill(endTime)
			this.sessionTimeInterval = setInterval(async () => {
				let endDate = new Date(endTime)
				endDate.setUTCMinutes(endDate.getUTCMinutes() + 1)
				endTime = endDate.getTime()
				this.sessionDuration = this.diffInTime(startTime, endTime)
				this.totalAmount = await this.getSessionAmountTill(endTime)
			}, 1000 * 60)
		},
		async checkSessionStatus() {
			// function to check if QR codes are usable
			const isQrUsable = (qr) => qr.length > 0 && qr[0].used < qr[0].usage
			const isQrExhausted = (qr) => qr.length > 0 && qr[0].used === qr[0].usage

			// filter QR codes by types
			const qrTypes = {
				mainDoorEnterQr: this.userLiveSession.sessionQRCodes.filter((a) => a.qrType === 'MDENT'),
				mainDoorExtQr: this.userLiveSession.sessionQRCodes.filter((a) => a.qrType === 'MDEXT'),
				mainDoorPickupEnterQr: this.userLiveSession.sessionQRCodes.filter(
					(a) => a.qrType === 'MDPENT'
				),
				mainDoorPickupExitQr: this.userLiveSession.sessionQRCodes.filter(
					(a) => a.qrType === 'MDPEXT'
				),
				firstEnterQr: this.userLiveSession.sessionQRCodes.filter((b) => b.qrType === 'FENT'),
				firstExitQr: this.userLiveSession.sessionQRCodes.filter((b) => b.qrType === 'FEXT'),
				enterQr: this.userLiveSession.sessionQRCodes.filter((c) => c.qrType === 'ENT'),
				exitQr: this.userLiveSession.sessionQRCodes.filter((c) => c.qrType === 'EXT'),
			}

			const sessionQrExpire = localStorage.getItem('qrTimeout')

			if (isQrUsable(qrTypes.mainDoorEnterQr)) {
				this.sessionCurrentSatus = 1
				if (qrTypes.mainDoorEnterQr[0].used === 0) {
					this.cancelSessionCta = true
				}
			} else if (isQrUsable(qrTypes.firstEnterQr) && qrTypes.mainDoorEnterQr.length === 0) {
				this.sessionCurrentSatus = 1
				if (qrTypes.firstEnterQr[0].used === 0) {
					this.cancelSessionCta = true
				}
			} else if (isQrUsable(qrTypes.firstExitQr)) {
				this.sessionCurrentSatus = 1
			} else if (isQrUsable(qrTypes.mainDoorExtQr)) {
				this.sessionCurrentSatus = 1
			} else if (
				(isQrExhausted(qrTypes.firstExitQr) &&
					(qrTypes.mainDoorExtQr.length === 0 || isQrExhausted(qrTypes.mainDoorExtQr)) &&
					qrTypes.mainDoorPickupEnterQr.length === 0 &&
					qrTypes.enterQr.length === 0) ||
				(qrTypes.enterQr.length > 0 && sessionQrExpire)
			) {
				this.sessionCurrentSatus = 2
			} else if (
				qrTypes.mainDoorPickupEnterQr.length > 0 &&
				isQrUsable([qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1]]) &&
				!sessionQrExpire
			) {
				this.sessionCurrentSatus = 1
			} else if (
				qrTypes.enterQr.length > 0 &&
				isQrUsable([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
				!sessionQrExpire
			) {
				this.sessionCurrentSatus = 1
			} else if (isQrUsable(qrTypes.exitQr)) {
				this.sessionCurrentSatus = 1
			} else if (qrTypes.mainDoorPickupExitQr) {
				this.sessionCurrentSatus = 1
			} else {
				this.sessionCurrentSatus = 0
			}
		},
		async goToSessionDetail() {
			// this.getSessionById({ sessionId: this.userLiveSession.id });
			let liveSessionDetailLink = await this.getLiveSessionDetailLink()
			liveSessionDetailLink && this.$router.push({ name: liveSessionDetailLink })
		},
		async cancelMySession() {
			try {
				const response = await SessionService.cancelCurrentSession(
					parseInt(this.userLiveSession?.id)
				)
				if (response) {
					await this.getUserLiveSession(
						this.userInAdmin?.id ? this.userInAdmin?.id : this.currentUser?.id
					)
				}
			} catch (error) {
				console.error('Error fetching session:', error)
			}
		},
	},
	async mounted() {
		await this.getUserLiveSession(
			this.userInAdmin?.id ? this.userInAdmin?.id : this.currentUser?.id
		)
		if (this.userLiveSession?.id) {
			// this.liveSessionDetailLink = await this.getLiveSessionDetailLink()
			this.initiateIntervalBasedUpdates()
			this.checkSessionStatus()
		}
	},
	watch: {
		async currentUser(newVal) {
			clearInterval(this.sessionTimeInterval)
			await this.getUserLiveSession(
				this.userInAdmin?.id ? this.userInAdmin?.id : this.currentUser?.id
			)
			if (this.userLiveSession?.id) {
				this.initiateIntervalBasedUpdates()
				this.checkSessionStatus()
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.sessionTimeInterval)
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';

.button-session {
	padding: 18px;
	border-radius: 4px;
	line-height: 25px;
	text-transform: none;
	max-height: none;

	@media (max-width: 768px) {
		width: 100%;
	}
}
.button-session-cancel {
	color: #e75131;
	font-size: 18px;
	font-family: 'Poppins Bold';
	text-align: center;
	cursor: pointer;
	text-decoration: underline;
}
.active-session-address-wrapper {
	display: flex;
	align-items: center;
	&-m {
		width: 4%;
		margin: 0 0 0 2rem;
	}
}

.sessions-history {
	.detail-container {
		padding: 16px;
	}

	background: #fff;
	color: #3c3c3b;
	font-family: 'Poppins Bold';

	.parking-address {
		font-family: 'Poppins Bold';
		font-size: 12px;
		margin: 0 0 0 0.5rem;
	}

	.details {
		margin-top: 20px;
		width: 100%;

		.row-values {
			padding-top: 0px;
		}

		.col {
			font-size: 16px;
			line-height: 25px;
			color: #3c3c3b;
			font-family: 'Poppins Bold';
			margin: 0%;
			padding-top: 0px;
			padding-bottom: 16px;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		.col2 {
			color: #898780;
			font-size: 12px;
			line-height: 18px;
			font-family: 'Poppins Bold';
			margin: 0%;
			padding-bottom: 4px;
			text-transform: uppercase;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		table {
			border-collapse: collapse;
			width: 100%;
		}

		td {
			text-align: left;
			// padding: 8px;
		}

		td:nth-child(2) {
			text-align: right;
		}

		.spacer {
			width: 0px;
			padding: 0;
		}
	}

	.button-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
	}
}

.h1 {
	color: #1b388a;
	text-align: center;
	font-weight: bold;
	font-family: Poppins Bold;
	margin-top: 0;
}

.detail-link {
	color: red;
	font-size: 12px;
	font-family: Poppins Bold;
	margin-top: 4px;
}

.paginator {
	background: #faf2d5;
	padding-top: 16px;
	display: flex;
	justify-content: center;

	.left {
		display: flex;
		cursor: pointer;
		margin-right: 8px;
	}

	.right {
		display: flex;
		cursor: pointer;
		margin-left: 8px;
	}

	.page-selector {
		cursor: pointer;
		padding: 0 2px;
		margin: 0 6px;

		&.selected {
			color: #e75131;
		}
	}

	.disabled {
		opacity: 0.3;
	}
}
</style>
