import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import Vue from 'vue'
import { findIndex, omit } from 'lodash/fp'
import { logger } from '@/logger'
import UserService from '../../services/userService'
import ParkingsService from '../../services/parkings'

// import { isNil, omit, find, findIndex, reject, map, filter, includes } from 'lodash/fp'
// import router from '@/router'

// import { addressTypes, SUBSCRIPTION_STATES, REQUEST_STATES, PROPOSAL_STATES, MAX_POI } from '@/config'
// import i18n from '@/i18nVeeValidate'

// Initial state
const initialState = () => ({
	all: [],
})

const state = initialState()

const getters = {}

const myParkingProps = `
	id
	name
	code
	latitude
	longitude
	capacityCargo
	capacityClassic
	active
	badgeAccess
	approved
	legacyAddress {
		nl
		fr
	}
	comment {
		nl
		fr
		en
	}
	parkingSpots {
	    id
	    amount,
	    name,
	    isCargo,
	    active
	}
`

const actions = {
	async getMyParkings({ commit } , id ) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query myParkings {
			// 			myParkings {
			// 				${myParkingProps}
			// 			}
			// 		}
			// 	`,
			// })
			const response = await UserService.getUserParkingById(id)
			const parkings = response.data

			commit('setMyParkings', parkings)
		} catch (e) {
			logger.error('Problem retrieving your parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},
	
	async upsertMyParking({ commit, dispatch }, payload) {
		commit('setLoading', true)

		const parkingPayload = {
			id: payload.id,
			name: payload.name,
			code: payload.code,
			legacyAddressNl: payload.legacyAddressNl,
			legacyAddressFr: payload.legacyAddressFr,
			active: payload.active,
			parkingSpots: payload.parkingSpots.map((ps) => ({
				id: ps.id,
				name: ps.name,
				isCargo: ps.isCargo,
				active: ps.active,
				amount: parseFloat(ps.amount),
			})),
			isCycloparking: payload.isCycloparking,
			badgeAccess: payload.badgeAccess,
			longitude: parseFloat(payload.longitude),
			latitude: parseFloat(payload.latitude),
			installationDate: payload.installationDate,
			commentNl: payload.commentNl || "",
			commentFr: payload.commentFr || "",
			commentEn: payload.commentEn || "",
			capacityClassic: parseInt(payload.capacityClassic, 10),
			capacityCargo: parseInt(payload.capacityCargo, 10),
			parkingTypeName: 'PrivateParkings',
			keyType: 0,
		}
		
		try {
			const response = await ParkingsService.upsertMyParking(parkingPayload)
			if (response.data) {
				parkingPayload.id
					? commit('updateParking', response.data)
					: commit('createParking', response.data)

				return true
			}
		} catch (e) {
			logger.error('parking creation error')
			dispatch('alert/error', e.message, { root: true })
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setMyParkings(state, parkings) {
		state.all = parkings
	},

	createParking(state, parking) {
		state.all.push(parking)
	},
	updateParking(state, parking) {
		// state.all.push(parking)
		const idx = findIndex({ id: parking.id }, state.all)

		if (idx >= 0) {
			Vue.set(state.all, idx, parking)
		}
	},
}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations,
}
